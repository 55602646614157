import { axiosInstance } from '../../Auth/components/axiosInterceptors';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setAlertFunc } from '../../../components/AlertToaster/AlertSlice';

const initialState = {
    name: "",
    pageNo: "",
    per_page: "",
    vendorsLoading: false,
    vendorsEditLoading: false,
    addSuccess: false,
    addLoading: false,
    vendors: [],
    VendorsTotalPages: 1,
    stateLoading: false,
    states: [],
    cityLoading: false,
    cities: [],
    categoryLoading: false,
    categories: [],
    vendorFilterValue: {
        searchVal: "",
        cities: [],
        cityLoading: false,
        displayStateValue: [],
        displayCityValue: [],
        currentStateRule: [],
        currentCityRule: [],

        // teams:[],
        services: [],
        servicesLoading: false,
        displayTeamsValue: [],
        displayServicesValue: [],
        currentTeamsRule: [],
        currentServicesRule: [],

        //actions
        status: 'all'
    },
    formValue: {
        name: "",
        city: "",
        states: "",
        gstin: "",
        spoc: "",
        mobile: "",
        category_id: [],
    },
    displayStateValue: [],
    displayCityValue: [],
    currentStateRule: [],
    currentCityRule: [],
    mulitpleSelected: [],
    vendorsDetailEditLoading: false,
    vendorSuccess: false
}


export const getVendors: any = createAsyncThunk(
    'vendors', async (
        thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`third_party/vendors`, {
            method: 'GET',
            params: {
                "search_key": thunkApi.search_key ?? '',
                "page": thunkApi.page ?? '',
                "per_page": thunkApi.per_page ?? '',
                "category_id": thunkApi.category_id ? JSON.stringify(thunkApi.category_id) : "",
                "state_id": thunkApi.city_id?.length === 0 && thunkApi.state_id?.length ? JSON.stringify(thunkApi.state_id) : '',
                // "state_id": thunkApi.state_id?.length ? JSON.stringify(thunkApi.state_id) : '',
                "city_id": thunkApi.city_id?.length ? JSON.stringify(thunkApi.city_id) : '',
                "status": thunkApi.status ?? '',
                "team_id": thunkApi.team_id ?? '',
            }
        })
        return response.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message });
    }
});


export const editVendor: any = createAsyncThunk(
    'editVendors', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`third_party/vendors`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi.data)
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))

            if (response.status === 200) {
                dispatch(getVendors({
                    search_key: thunkApi?.filterValues?.searchVal || "",
                    page: thunkApi.pages.page,
                    per_page: thunkApi.pages.selectedValue,
                    status: thunkApi?.filterValues?.selectedStatus?.title,
                    "category_id": thunkApi?.filterValues?.selectedServices?.map((service: any) => service.id),
                    "state_id": thunkApi?.filterValues?.selectedStates?.map((state: any) => state.id),
                    "city_id": thunkApi?.filterValues?.selectedCities?.map((city: any) => city.id),
                    "team_id": thunkApi.team_id ?? ''
                }))
            }
            return response
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    })


export const getStates = createAsyncThunk(
    'states', async (_, thunkApi: any) => {
        // let obj = {
        //     "country_id": country_id ?? ''
        // }
        try {
            const response = await axiosInstance(`states`, {
                method: 'GET',
                // params: country_id ? obj : ''
            })
            return await response.data;
        } catch (error: any) {
            // return thunkAPI.rejectWithValue({ error: error.message });
        }
    })
export const getCity = createAsyncThunk(
    'city', async (state_id: any, thunkAPI: any) => {
        try {
            const response = await axiosInstance(`cities`, {
                method: 'GET',
                params: {
                    "state_id": state_id ?? ''
                }
            });
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })

export const getCategory = createAsyncThunk(
    'category', async (module_id: number, thunkAPI: any) => {
        try {
            const response = await axiosInstance(`categories`, {
                method: 'GET',
                params: {
                    "module_id": module_id ?? '',
                    "is_visible": "True"
                }
            });
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })

export const addVendor: any = createAsyncThunk('addVendor',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const res = await axiosInstance('third_party/vendors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(thunkAPI.data)
            })
            dispatch(setAlertFunc({ type: 'success', msg: res?.data?.message }));
            res?.status === 200 && dispatch(getVendors(thunkAPI.obj))
            return res.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)
export const editDetailVendor: any = createAsyncThunk(
    'editDetailVendor', async (thunkApi: any, { getState, dispatch }) => {

        try {
            const response: any = await axiosInstance(`third_party/edit_vendor`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi.data.formValues)
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getVendors({
                search_key: thunkApi?.data?.filterValues?.searchVal || "",
                page: thunkApi.pages.page,
                per_page: thunkApi.pages.selectedValue,
                status: thunkApi?.data?.filterValues?.selectedStatus?.title,
                "category_id": thunkApi?.category_id ?? '',
                "state_id": thunkApi?.data?.filterValues?.selectedStates?.map((state: any) => state.id),
                "city_id": thunkApi?.data?.filterValues?.selectedCities?.map((city: any) => city.id),
                "team_id": thunkApi.team_id ?? ''
            }))
            return response
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    })
const VendorSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        setFormValue: (state: any, payload: any) => {
            state.formValue = payload?.payload
        },
        setSearchValue: (state: any, payload: any) => {
            state.searchVal = payload?.payload
        },
        setStateDisplayValue: (state: any, payload: any) => {
            state.displayStateValue = payload?.payload
        },
        setCityDisplayValue: (state: any, payload: any) => {
            state.displayCityValue = payload?.payload
        },
        setCurrentStateRules: (state: any, payload: any) => {
            state.currentStateRule = payload?.payload
        },
        setCurrentCityRules: (state: any, payload: any) => {
            state.currentCityRule = payload?.payload
        },
        setFilterStateDisplayValue: (state: any, payload: any) => {
            state.vendorFilterValue.displayStateValue = payload?.payload
        },
        setFilterCityDisplayValue: (state: any, payload: any) => {
            state.vendorFilterValue.displayCityValue = payload?.payload
        },
        setFilterCurrentStateRules: (state: any, payload: any) => {
            state.vendorFilterValue.currentStateRule = payload?.payload
        },
        setFilterCurrentCityRules: (state: any, payload: any) => {
            state.vendorFilterValue.currentCityRule = payload?.payload
        },
        setFilterTeamsDisplayValue: (state: any, payload: any) => {
            state.vendorFilterValue.displayTeamsValue = payload?.payload
        },
        setFilterServicesDisplayValue: (state: any, payload: any) => {
            state.vendorFilterValue.displayServicesValue = payload?.payload
        },
        setFilterCurrentTeamsRules: (state: any, payload: any) => {
            state.vendorFilterValue.currentTeamsRule = payload?.payload
        },
        setFilterCurrentServicesRules: (state: any, payload: any) => {
            state.vendorFilterValue.currentServicesRule = payload?.payload
        },
        setStatus: (state: any, payload: any) => {
            state.vendorFilterValue.status = payload?.payload
        },
        resetVendorFilter: (state: any) => {
            state.vendorFilterValue = initialState.vendorFilterValue
        },
    },
    extraReducers: (builder: any) => {
        return builder.addCase(getVendors.pending, (state: any) => {
            state.vendorsLoading = true
            state.vendors = []
            state.VendorsTotalPages = 0
            state.addSuccess = false
        }),
            builder.addCase(getVendors.fulfilled, (state: any, { payload }: any) => {
                state.vendorsLoading = false
                state.vendors = payload
                state.VendorsTotalPages = payload.totalPages
                state.addSuccess = false
            }),
            builder.addCase(getVendors.rejected, (state: any) => {
                state.vendorsLoading = false
                state.vendors = []
                state.VendorsTotalPages = 0
            }),
            builder.addCase(addVendor.pending, (state: any) => {
                state.addLoading = true
                state.addSuccess = false
            }),
            builder.addCase(addVendor.fulfilled, (state: any) => {
                state.addLoading = false
                state.addSuccess = true
            }),
            builder.addCase(addVendor.rejected, (state: any) => {
                state.addLoading = false
            }),
            builder.addCase(getStates.pending, (state: any) => {
                state.stateLoading = true

            }),
            builder.addCase(getStates.fulfilled, (state: any, { payload }: any) => {
                state.stateLoading = false
                state.states = payload
            }),
            builder.addCase(getStates.rejected, (state: any) => {
                state.stateLoading = false
            }),
            builder.addCase(getCity.pending, (state: any) => {
                state.cityLoading = true

            }),
            builder.addCase(getCity.fulfilled, (state: any, { payload }: any) => {
                state.cityLoading = false
                state.cities = payload
            }),
            builder.addCase(getCity.rejected, (state: any) => {
                state.cityLoading = false
                state.cities = []
            }),
            builder.addCase(getCategory.pending, (state: any) => {
                state.categoryLoading = true

            }),
            builder.addCase(getCategory.fulfilled, (state: any, { payload }: any) => {
                state.categoryLoading = false
                state.categories = payload
            }),
            builder.addCase(getCategory.rejected, (state: any) => {
                state.categoryLoading = false
            }),
            builder.addCase(editVendor.pending, (state: any) => {
                state.vendorsEditLoading = true
            }),
            builder.addCase(editVendor.fulfilled, (state: any, { payload }: any) => {
                state.vendorsEditLoading = false
            }),
            builder.addCase(editVendor.rejected, (state: any) => {
                state.vendorsEditLoading = false
            }),
            builder.addCase(editDetailVendor.pending, (state: any) => {
                state.vendorsDetailEditLoading = true
            }),
            builder.addCase(editDetailVendor.fulfilled, (state: any, { payload }: any) => {
                state.vendorsDetailEditLoading = false
                state.vendorSuccess = true
            }),
            builder.addCase(editDetailVendor.rejected, (state: any) => {
                state.vendorsDetailEditLoading = false
            })
    }
})
export const { setFormValue, setSearchValue, setFilterTeamsDisplayValue, setFilterServicesDisplayValue, setFilterCurrentServicesRules, setFilterCurrentTeamsRules, setFilterStateDisplayValue, setFilterCityDisplayValue, setFilterCurrentCityRules, setFilterCurrentStateRules, setStateDisplayValue, setCityDisplayValue, setCurrentCityRules, setCurrentStateRules, setStatus, resetVendorFilter } = VendorSlice.actions
export const getAllVendors = (state: any) => state.vendors;

export default VendorSlice.reducer