import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    otp_tokens: '',
}

const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setOtpToken: (state, { payload }) => {
            state.otp_tokens = payload
        },
        resetProfileSlice: (state) => {
            state.otp_tokens = ""
        }
    },
})

export const { setOtpToken, resetProfileSlice } = ProfileSlice.actions

export default ProfileSlice.reducer
