import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from './axiosInterceptors'
const initialState = {
    resendSuccess: false,
    resendLoading: false,
}

    export const resendOTP: any = createAsyncThunk(
        'Verifylogin/send-otp',
        async (thunkApi: any) => {
                
                const response: any = await axiosInstance(`login/resend-otp`, {
                    method: 'POST',
                    data: {phone: thunkApi?.phone}
                }).then(
                    (data: any) => {
                        if(data?.status === 200){
                            thunkApi?.setHideElement(true)
                        }
                        return data;
                    }
                )
        }
    );
    
const loginSlice = createSlice({
    name: 'ResendOTP',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(resendOTP.pending, (state: any) => {
            state.resendLoading = true
            state.resendSuccess = false

        }),
            builder.addCase(resendOTP.fulfilled, (state: any) => {
                state.resendLoading = false
                state.resendSuccess = true
            }),
            builder.addCase(resendOTP.rejected, (state: any) => {
                state.resendLoading = false
                state.resendSuccess = false
            })
    }
})

export default loginSlice.reducer