import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { axiosInstance } from '../../.../../../Auth/components/axiosInterceptors';
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';
import urls from '../../../../enums/urlList';

const initialState = {
    roles: [],
    rolesLoading: false,
    menuRolesLoading: false,
    templateLoading: false,
    rolesArr: [],
    templateRoles: {},
    currentRole: [],
    addRoleLoading: false,
    editRoleLoading: false
}

export const getRolesList = createAsyncThunk(
    'getroleslist', async (thunkApi:any) => {
        try {
            const params = {
                search_key : thunkApi?.search_key,
                team_id : thunkApi?.team_id
            }
            const response: any = await axiosInstance(`${urls.base_url}roles`, {
                method: 'GET',
                params : params
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getRole = createAsyncThunk(
    'getroles', async (thunkApi: any) => {
        try {
            let response: any;
            // thunkApi.team_id != undefined ?
            //     response = await axiosInstance.get(`${urls.base_url}role?team_id=${thunkApi.team_id}`) :
            //     response = await axiosInstance.get(`${urls.base_url}role?position_id=${thunkApi.position_id}`)
            thunkApi.position_id ?
                response = await axiosInstance.get(`${urls.base_url}role?position_id=${thunkApi.position_id}`):
                response = await axiosInstance.get(`${urls.base_url}role?team_id=${thunkApi.team_id ?  thunkApi.team_id : ""}`) 
            return await response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getMenuRoles = createAsyncThunk(
    'getmenuroles', async (role_id: any, thunkAPI: any) => {
        try {
            const params = {
                id : role_id
            }
            const response = await axiosInstance.get(`${urls.base_url}role`,{params:params});
            // const response = await axiosInstance.get(`role?id=${role_id}`);
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

export const getRoleTemplate = createAsyncThunk(
    'rolestemplate', async (blank: string, thunkAPI: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}role-template`, {
                method: 'GET',
                data: JSON.stringify(thunkAPI)
            })
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

export const addRole: any = createAsyncThunk(
    'addRoles',
    async (thunkApi: any, { getState, dispatch }) => {

        const response: any = await axiosInstance(`${urls.base_url}role`, {
            method: 'POST',
            data: JSON.stringify(thunkApi)
        }).then(
            (data: any) => {
                return data;
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.response.data.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        response.status === 201 && await dispatch(getRolesList({search_key:"",team_id:""}))

        return response
    }
);
export const editRole: any = createAsyncThunk(
    'editRoles',
    async (thunkApi: any, { getState, dispatch }) => {

        const response: any = await axiosInstance(`${urls.base_url}role`, {
            method: 'PUT',
            data: JSON.stringify(thunkApi)
        }).then(
            (data: any) => {
                return data;
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.response.data.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        response.status === 200 && await dispatch(getMenuRoles(thunkApi.id))
        response.status === 200 && await dispatch(getRolesList({search_key:"",team_id:""}))

        return response
    }
);

const RoleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
    },
    extraReducers: (builder: any) => {
        return builder.addCase(getRolesList.pending, (state: any) => {
            state.rolesLoading = true
            state.roles = []
        }),
            builder.addCase(getRolesList.fulfilled, (state: any, { payload }: any) => {
                state.rolesLoading = false
                state.roles = payload
            }),
            builder.addCase(getRolesList.rejected, (state: any) => {
                state.rolesLoading = false
                state.roles = []
            }),
            builder.addCase(getRoleTemplate.pending, (state: any) => {
                state.templateLoading = true
            }),
            builder.addCase(getRoleTemplate.fulfilled, (state: any, { payload }: any) => {
                state.templateLoading = false
                state.templateRoles = payload
            }),
            builder.addCase(getRoleTemplate.rejected, (state: any) => {
                state.templateLoading = false
            }),
            builder.addCase(getRole.pending, (state: any) => {
                state.rolesLoading = true
            }),
            builder.addCase(getRole.fulfilled, (state: any, { payload }: any) => {
                state.rolesLoading = false
                state.rolesArr = payload
            }),
            builder.addCase(getRole.rejected, (state: any) => {
                state.rolesLoading = false
            }),
            builder.addCase(getMenuRoles.pending, (state: any) => {
                state.menuRolesLoading = true
            }),
            builder.addCase(getMenuRoles.fulfilled, (state: any, { payload }: any) => {
                state.menuRolesLoading = false
                state.currentRole = payload
            }),
            builder.addCase(getMenuRoles.rejected, (state: any) => {
                state.menuRolesLoading = false
            }),
            builder.addCase(addRole.pending, (state: any) => {
                state.addRoleLoading = true
                state.addSuccess = false
            }),
            builder.addCase(addRole.fulfilled, (state: any) => {
                state.addRoleLoading = false
                state.addSuccess = true
            }),
            builder.addCase(addRole.rejected, (state: any) => {
                state.addRoleLoading = false
            }),
            builder.addCase(editRole.pending, (state: any) => {
                state.editRoleLoading = true
                state.addSuccess = false
            }),
            builder.addCase(editRole.fulfilled, (state: any) => {
                state.editRoleLoading = false
                state.addSuccess = true
            }),
            builder.addCase(editRole.rejected, (state: any) => {
                state.editRoleLoading = false
            })
    }
})
export const getAllRolesTemplate = (state: any) => state.roles.templateRoles;
export const getAllRolesTemplateData = (state: any) => state.roles;
export const getAllMenuRoles = (state: any) => state.roles.currentRole;
export const getAddRoleLoading = (state: any) => state.roles.addRoleLoading;
export const getEditRoleLoading = (state: any) => state.roles.editRoleLoading;
export const getMenuRoleLoading = (state: any) => state.roles.menuRolesLoading;

const { actions, reducer } = RoleSlice
export default RoleSlice.reducer