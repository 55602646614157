import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { ButtonSmall } from "../../../components/Button"
import { TStore } from "../../../store/Store"

import not_found from "../../../assets/images/404_error.svg"
import styles from "../error.module.scss"

const NotFound = () => {
    const navigate = useNavigate()
    const { redirectModule } = useSelector((store: TStore) => store.verifyLogin)
    const handleRedirect = useCallback(() => {
        const routeMap: {
            [key: string]: string
        } = {
            "Projects": "/Projects",
            "Operations": "/Installation",
            "Logistics": "/Logistics",
            "Customer Support": "/CustomerSupport",
            "Onboarding": "/Stations",
            "Finance": "/Finance",
            "Tech Support": "/TechSupport",
            "Network Reliability": "/NetworkReliability",
            "Settings": "/Settings",
        }
        navigate(routeMap[redirectModule] ?? "/Settings")
    }, [redirectModule, navigate])

    return (
        <div className={styles.wrapper} style={{ height: "100vh" }}>
            <div className={styles.container}>
                <div className={styles.image_container}>
                    <img src={not_found} width={340} height={240} alt="Page not found!" />
                </div>
                <div className={styles.text_section}>
                    <div className={styles.bold_section}>404 Error</div>
                    <div>Oops! Page not found</div>
                </div>
                <div className={styles.button_container}>
                    <ButtonSmall
                        className={`${styles.reload_button} ${styles.redirect_button}`}
                        onClick={handleRedirect}>
                        Return to home
                    </ButtonSmall>
                </div>
            </div>
        </div>
    )
}

export default NotFound
