import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../Auth/components/axiosInterceptors'
import urls from "../../enums/urlList";

import { setAlertFunc } from '../../components/AlertToaster/AlertSlice'
const initialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    ProjectsFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        ETDStartDate: '',
        ETDEndDate: '',
        ClosureStartDate: '',
        ClosureEndDate: '',
        dealType: '',
        // TaxationStartDate: '',
        // TaxationEndDate: '',
        search_key: '',
        sow: '',
        parent_status: 'all_tab'
    },
    ProjectsTabsLoading: false,
    ProjectsTabsData: [],
    ProjectsLoading: false,
    ProjectsData: [],
    ProjectsDetailLoading: false,
    ProjectsDealDetailData: [],
    ProjectsHistoryLoading: false,
    ProjectsHistoryData: [],
    ProjectsOthersLoading: false,
    ProjectsOthersData: [],
    ProjectsButtonLoading: false,
    ProjectsButtonData: [],
    deleteTransactionLoading: false,
    deleteTransactionSuccess: false,
    deletePaymentSuccess: false,
    deletePaymentLoading: false,
    addPurchaseOrderSuccess: false,
    addPurchaseOrderLoading: false,
    editTicketDetailsSuccess: false,
    editTicketDetailsLoading: false,
    editTicketStatusSuccess: false,
    editTicketStatusLoading: false,
    skipStatusLoading: false,
    skipStatusData: [],
    editSkipStatusSuccess: false,
    editSkipStatusLoading: false,
    ChargerTemplateLoading: false,
    addTransactionSuccess: false,
    addTransactionLoading: false,
    verifyTransactionSuccess: false,
    verifyTransactionLoading: false,
    addPaymentTermSuccess: false,
    addPaymentTermLoading: false,
    ChargerTemplateData: [],
    PaymentConditionLoading: false,
    PaymentConditionData: [],
    PaymentTimeLoading: false,
    PaymentTimeData: [],
    ProjectsDealTab: 0,
    selectedTab: 0,
    //form
    addTicketPostLoading: false,
    addTicketPostSuccess: false,
    ops_ticket_id: "",
    projects_ticket_id: "",
    addTicketPatchLoading: false,
    addTicketPatchSuccess: false,
    getFormStepDetailLoading: false,
    getFormStepDetailSuccess: false,
    currentStepDetail: {},
    getHostingAddressSuccess: false,
    getHostingAddressLoading: false,
    hostingAddress: [],
    chargerTemplateUrl: [],
    cpoList: [],
    getDropTicketSuccess: false,
    getDropTicketLoading: false,
    DropTicket: [],
    getLinkedSurveyIdsSuccess: false,
    getLinkedSurveyIdsLoading: false,
    LinkedSurveyIds: []
}


/* Raise Ticket Form  */
export const addTicketPost: any = createAsyncThunk(
    'addTicketPost', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}projects/tickets`, {
                method: 'POST',
                data: thunkApi.formData,
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const addTicketPatch: any = createAsyncThunk(
    'addTicketPatch', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}projects/tickets`, {
                method: 'PATCH',
                data: thunkApi.formData,
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.[0]?.message }))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const getFormStepDetail: any = createAsyncThunk(
    'getFormStepDetail', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`projects/get_project_details`, {
                method: 'GET',
                params: {
                    page: thunkApi.page,
                    project_id: thunkApi.project_id
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getCpoList: any = createAsyncThunk(
    'getCpoList', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`onboarding/host/listcpo`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const getChargerTemplate: any = createAsyncThunk(
    'getChargerTemplate', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`projects/charger_template`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });


export const getHostingAddress: any = createAsyncThunk("getHostingAddress", async (thunkApi: any, { dispatch }) => {
    try {
        const response: any = await axiosInstance(`hosting_address`, {
            method: "GET",
            params: {
                city_id: thunkApi?.city_id ?? "",
                state_id: thunkApi?.state_id ?? ""
            },
        })
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error?.response?.data?.data?.message }))
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getLinkedSurveyIds: any = createAsyncThunk(
    'getLinkedSurveyIds', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/survey/link_survey`, {
                method: 'GET',
                // params: { "city_id": JSON.stringify(thunkApi?.city_id)}
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const selectSurveyId: any = createAsyncThunk(
    'selectSurveyId', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/survey/link_survey`, {
                method: 'PUT',
                data: thunkApi,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getLinkedSurveyIds())
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const dropTicket: any = createAsyncThunk(
    'dropTicket', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`projects/drop_ticket`, {
                method: 'GET',
                params: { "project_id": thunkApi?.ticket_id, reason_id: thunkApi?.reason_id }
            })
            const currentState: any = getState();
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getProjectsData({ data: currentState.Projects.ProjectsFormValue, page: currentState.Projects.page, selectedValue: currentState.Projects.selectedValue }))
            response.status === 200 && dispatch(getProjectsTabs({ data: currentState.Projects.ProjectsFormValue, page: currentState.Projects.page, selectedValue: currentState.Projects.selectedValue }))
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

/////////

export const getProjectsTabs: any = createAsyncThunk(
    'getProjectsTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`projects/ticket_total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_id': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'due_start': obj.data.ClosureStartDate ?? '',
                    'deal_type': obj.data.dealType ?? '',
                    'due_end': obj.data.ClosureEndDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getProjectsData: any = createAsyncThunk(
    'getProjectsData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`projects/${obj.data.parent_status ?? 'all_tab'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_id': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'due_start': obj.data.ClosureStartDate ?? '',
                    'due_end': obj.data.ClosureEndDate ?? '',
                    'deal_type': obj.data.dealType ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getTicketDetails: any = createAsyncThunk(
    'ticketDetail', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`projects/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getTicketHistory: any = createAsyncThunk(
    'history', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`projects/ticket_history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getOtherDetails: any = createAsyncThunk(
    'getOtherDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`projects/other_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSkipStatuses: any = createAsyncThunk(
    'getStatuses', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`projects/statuses`, {
                method: 'GET',
                params: {
                    "search_key": obj.search_key ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getButtonDropdown: any = createAsyncThunk(
    'getButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`projects/button`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getPaymentConditions: any = createAsyncThunk(
    'getPaymentConditions', async (__, thunkApi) => {
        try {
            const response: any = await axiosInstance(`projects/conditions`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getPaymentTime: any = createAsyncThunk(
    'getPaymentTime', async (__, thunkApi) => {
        try {
            const response: any = await axiosInstance(`projects/time_uom`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addTicketDetails: any = createAsyncThunk(
    'addFormat',
    async (thunkAPI: any, { getState, dispatch }) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}projects/ticket_details`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkAPI.data.get('ticket_id')))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addPaymentTerm: any = createAsyncThunk(
    'addPaymentTerm',
    async (thunkAPI: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}finance/payment_term`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getTicketDetails(thunkAPI?.ticketId))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addTransaction: any = createAsyncThunk(
    'addTransaction',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}finance/transaction`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getTicketDetails(thunkAPI?.ticketId))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const VerifyTransaction: any = createAsyncThunk(
    'VerifyTransaction', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}finance/verify_transaction`, {
                method: 'POST',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi?.ticketId))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi?.ticketId))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editTicketDetails: any = createAsyncThunk(
    'editTicketDetails', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}projects/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editSkipStatus: any = createAsyncThunk(
    'editSkipStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}projects/skip_to_status`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editTicketStatus: any = createAsyncThunk(
    'editTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}projects/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const deleteTransaction: any = createAsyncThunk(
    'deleteTransaction', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`finance/transaction?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&transaction_id=${thunkApi?.transaction_id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.project_ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
export const deletePaymentTerm: any = createAsyncThunk(
    'deletePaymentTerm', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`finance/payment_term?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&payment_term_id=${thunkApi?.payment_term_id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.project_ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
const ProjectsSlice = createSlice({
    name: 'Projects',
    initialState,
    reducers: {
        resetRaiseTicketForm: (state: any) => {
            state.addTicketPostLoading = false
            state.addTicketPostSuccess = false
            state.ops_ticket_id = ""
            state.projects_ticket_id = ""
            state.addTicketPatchLoading = false
            state.addTicketPatchSuccess = false
            state.getFormStepDetailLoading = false
            state.getFormStepDetailSuccess = false
            state.currentStepDetail = {}
            state.getHostingAddressSuccess = false
            state.getHostingAddressLoading = false
            state.hostingAddress = []
            state.getLinkedSurveyIdsLoading = false
            state.getLinkedSurveyIdsSuccess = false
            state.LinkedSurveyIds = []
            state.chargerTemplateUrl = []
        },
        resetProjectStates: (state: any) => {
            state.users = []
            state.loading = false
            state.addSuccess = false
            state.totalPages = 1
            state.page = 1
            state.selectedValue = 10
            state.addLoading = false
            state.editLoading = false
            state.EditSuccess = false
            state.chargerDisplayValues = []
            state.chargerCurrentRules = []
            state.StateDisplayValues = []
            state.StateCurrentRules = []
            state.CityDisplayValues = []
            state.CityCurrentRules = []
            state.currentRules = []
            state.displayValues = []
            state.ProjectsFormValue = {
                cityId: [],
                stateId: [],
                chargerIds: [],
                statusId: [],
                age_op: '',
                age: [],
                more_than_age: '',
                between_age1: '',
                between_age2: '',
                less_than_age: '',
                assigned_to: '',
                TicketStartDate: '',
                TicketEndDate: '',
                ETDStartDate: '',
                ETDEndDate: '',
                ClosureStartDate: '',
                ClosureEndDate: '',
                // TaxationStartDate: '',
                // TaxationEndDate: '',
                search_key: '',
                sow: '',
                parent_status: 'all_tab'
            }
            state.ProjectsTabsLoading = false
            state.ProjectsTabsData = []
            state.ProjectsLoading = false
            state.ProjectsData = []
            state.ProjectsDetailLoading = false
            // state.ProjectsDealDetailData= []
            state.ProjectsHistoryLoading = false
            state.ProjectsHistoryData = []
            state.ProjectsOthersLoading = false
            state.ProjectsOthersData = []
            state.ProjectsButtonLoading = false
            state.ProjectsButtonData = []
            state.deleteTransactionLoading = false
            state.deleteTransactionSuccess = false
            state.deletePaymentSuccess = false
            state.deletePaymentLoading = false
            state.addPurchaseOrderSuccess = false
            state.addPurchaseOrderLoading = false
            state.editTicketDetailsSuccess = false
            state.editTicketDetailsLoading = false
            state.editTicketStatusSuccess = false
            state.editTicketStatusLoading = false
            state.skipStatusLoading = false
            state.skipStatusData = []
            state.editSkipStatusSuccess = false
            state.editSkipStatusLoading = false
            state.ChargerTemplateLoading = false
            state.addTransactionSuccess = false
            state.addTransactionLoading = false
            state.verifyTransactionSuccess = false
            state.verifyTransactionLoading = false
            state.addPaymentTermSuccess = false
            state.addPaymentTermLoading = false
            state.ChargerTemplateData = []
            state.PaymentConditionLoading = false
            state.PaymentConditionData = []
            state.PaymentTimeLoading = false
            state.PaymentTimeData = []
            state.ProjectsDealTab = 0
            state.selectedTab = 0
            //form
            state.addTicketPostLoading = false
            state.addTicketPostSuccess = false
            state.ops_ticket_id = ""
            state.projects_ticket_id = ""
            state.addTicketPatchLoading = false
            state.addTicketPatchSuccess = false
            state.getFormStepDetailLoading = false
            state.getFormStepDetailSuccess = false
            state.currentStepDetail = {}
            state.getHostingAddressSuccess = false
            state.getHostingAddressLoading = false
            state.hostingAddress = []
            state.getLinkedSurveyIdsLoading = false
            state.getLinkedSurveyIdsSuccess = false
            state.LinkedSurveyIds = []
            state.chargerTemplateUrl = []
            state.cpoList = []
        },
        setProjectsForm: (state: any, payload: any) => {
            state.ProjectsFormValue = payload?.payload
        },
        setDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state: any, payload: any) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state: any, payload: any) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state: any, payload: any) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state: any, payload: any) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state: any, payload: any) => {
            state.ProjectsDealTab = payload?.payload
        },
        setSelectedTab: (state: any, payload: any) => {
            state.selectedTab = payload?.payload
        },

    },
    extraReducers: (builder: any) => {
        return builder.addCase(getProjectsTabs.pending, (state: any) => {
            state.ProjectsTabsLoading = true
        }),
            builder.addCase(getProjectsTabs.fulfilled, (state: any, { payload }: any) => {
                state.ProjectsTabsLoading = false
                state.ProjectsTabsData = payload.data.message
            }),
            builder.addCase(getProjectsTabs.rejected, (state: any) => {
                state.ProjectsTabsLoading = false
                state.ProjectsTabsData = []
                state.DealDetailsData = []
            }),

            //form
            builder.addCase(addTicketPost.pending, (state: any) => {
                state.addTicketPostLoading = true
                state.addTicketPostSuccess = false
                state.ops_ticket_id = ""
                state.projects_ticket_id = ""
            }),
            builder.addCase(addTicketPost.fulfilled, (state: any, { payload }: any) => {
                state.addTicketPostLoading = false
                state.addTicketPostSuccess = true
                state.ops_ticket_id = payload?.[0]?.ops_ticket_id
                state.projects_ticket_id = payload?.[0]?.project_id
            }),
            builder.addCase(addTicketPost.rejected, (state: any) => {
                state.addTicketPostLoading = false
                state.addTicketPostSuccess = false
                state.ops_ticket_id = ""
                state.projects_ticket_id = ""
            }),


            builder.addCase(addTicketPatch.pending, (state: any) => {
                state.addTicketPatchLoading = true
                state.addTicketPatchSuccess = false
            }),
            builder.addCase(addTicketPatch.fulfilled, (state: any) => {
                state.addTicketPatchLoading = false
                state.addTicketPatchSuccess = true
            }),
            builder.addCase(addTicketPatch.rejected, (state: any, { payload }: any) => {
                state.addTicketPostLoading = false
                state.addTicketPatchSuccess = false
            }),

            builder.addCase(getFormStepDetail.pending, (state: any) => {
                state.getFormStepDetailLoading = true
                state.getFormStepDetailSuccess = false
                state.currentStepDetail = []
            }),
            builder.addCase(getFormStepDetail.fulfilled, (state: any, { payload }: any) => {
                state.getFormStepDetailLoading = false
                state.getFormStepDetailSuccess = true
                state.currentStepDetail = payload
            }),
            builder.addCase(getFormStepDetail.rejected, (state: any) => {
                state.getFormStepDetailLoading = false
                state.getFormStepDetailSuccess = false
                state.currentStepDetail = []
            }),

            builder.addCase(getCpoList.fulfilled, (state: any, { payload }: any) => {
                state.cpoList = payload?.data?.message
            }),

            builder.addCase(getChargerTemplate.pending, (state: any) => {
                state.chargerTemplateUrl = ""
            }),
            builder.addCase(getChargerTemplate.fulfilled, (state: any, { payload }: any) => {
                state.chargerTemplateUrl = payload?.[0]?.url
            }),
            builder.addCase(getChargerTemplate.rejected, (state: any) => {
                state.chargerTemplateUrl = ""
            }),

            builder.addCase(getHostingAddress.pending, (state: any) => {
                state.getHostingAddressLoading = true
                state.getHostingAddressSuccess = false
                state.hostingAddress = ""
            }),
            builder.addCase(getHostingAddress.fulfilled, (state: any, { payload }: any) => {
                state.getHostingAddressLoading = false
                state.getHostingAddressSuccess = true
                state.hostingAddress = payload?.data
            }),
            builder.addCase(getHostingAddress.rejected, (state: any) => {
                state.getHostingAddressLoading = false
                state.getHostingAddressSuccess = false
                state.hostingAddress = ""
            }),
            builder.addCase(getLinkedSurveyIds.pending, (state: any) => {
                state.getLinkedSurveyIdsLoading = true
                state.getLinkedSurveyIdsSuccess = false
                state.LinkedSurveyIds = []
            }),
            builder.addCase(getLinkedSurveyIds.fulfilled, (state: any, { payload }: any) => {
                state.getLinkedSurveyIdsLoading = false
                state.getLinkedSurveyIdsSuccess = true
                state.LinkedSurveyIds = payload?.data
            }),
            builder.addCase(getLinkedSurveyIds.rejected, (state: any) => {
                state.getLinkedSurveyIdsLoading = false
                state.getLinkedSurveyIdsSuccess = false
                state.LinkedSurveyIds = []
            }),
            builder.addCase(dropTicket.pending, (state: any) => {
                state.getDropTicketLoading = true
                state.getDropTicketSuccess = false
                state.DropTicket = ""
            }),
            builder.addCase(dropTicket.fulfilled, (state: any, { payload }: any) => {
                state.getDropTicketLoading = false
                state.getDropTicketSuccess = true
                state.DropTicket = payload
            }),
            builder.addCase(dropTicket.rejected, (state: any) => {
                state.getDropTicketLoading = false
                state.getDropTicketSuccess = false
                state.DropTicket = ""
            }),
            //


            builder.addCase(getProjectsData.pending, (state: any) => {
                state.ProjectsLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getProjectsData.fulfilled, (state: any, { payload }: any) => {
                state.ProjectsLoading = false
                state.ProjectsData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getProjectsData.rejected, (state: any) => {
                state.ProjectsLoading = false
                state.ProjectsData = []
                state.totalPages = 0
            }),
            builder.addCase(getTicketDetails.pending, (state: any) => {
                state.ProjectsDetailLoading = true
                // state.editTicketStatusSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(getTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.ProjectsDetailLoading = false
                state.editTicketStatusSuccess = false
                state.editTicketDetailsSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.ProjectsDealDetailData = payload
            }),
            builder.addCase(getTicketDetails.rejected, (state: any) => {
                state.ProjectsDetailLoading = false
                state.ProjectsDealDetailData = []
            }),
            builder.addCase(getTicketHistory.pending, (state: any) => {
                state.ProjectsHistoryLoading = true
            }),
            builder.addCase(getTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.ProjectsHistoryLoading = false
                state.ProjectsHistoryData = payload
            }),
            builder.addCase(getTicketHistory.rejected, (state: any) => {
                state.ProjectsHistoryLoading = false
                state.ProjectsHistoryData = []
            }),
            builder.addCase(getOtherDetails.pending, (state: any) => {
                state.ProjectsOthersLoading = true
            }),
            builder.addCase(getOtherDetails.fulfilled, (state: any, { payload }: any) => {
                state.ProjectsOthersLoading = false
                state.ProjectsOthersData = payload
            }),
            builder.addCase(getOtherDetails.rejected, (state: any) => {
                state.ProjectsOthersLoading = false
                state.ProjectsOthersData = []
            }),
            builder.addCase(getSkipStatuses.pending, (state: any) => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state: any, { payload }: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, (state: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            builder.addCase(getPaymentTime.pending, (state: any) => {
                state.PaymentTimeLoading = true
            }),
            builder.addCase(getPaymentTime.fulfilled, (state: any, { payload }: any) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = payload
            }),
            builder.addCase(getPaymentTime.rejected, (state: any) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = []
            }),
            builder.addCase(getPaymentConditions.pending, (state: any) => {
                state.PaymentConditionLoading = true
            }),
            builder.addCase(getPaymentConditions.fulfilled, (state: any, { payload }: any) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = payload
            }),
            builder.addCase(getPaymentConditions.rejected, (state: any) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = []
            }),
            builder.addCase(getButtonDropdown.pending, (state: any) => {
                state.ProjectsButtonLoading = true
            }),
            builder.addCase(getButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.ProjectsButtonLoading = false
                state.ProjectsButtonData = payload?.data
            }),
            builder.addCase(getButtonDropdown.rejected, (state: any) => {
                state.ProjectsButtonLoading = false
                state.ProjectsButtonData = []
            }),
            builder.addCase(deleteTransaction.pending, (state: any) => {
                state.deleteTransactionLoading = true
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deleteTransaction.fulfilled, (state: any, { payload }: any) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = true
            }),
            builder.addCase(deleteTransaction.rejected, (state: any) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deletePaymentTerm.pending, (state: any) => {
                state.deletePaymentLoading = true
                state.deletePaymentSuccess = false
            }),
            builder.addCase(deletePaymentTerm.fulfilled, (state: any, { payload }: any) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = true
            }),
            builder.addCase(deletePaymentTerm.rejected, (state: any) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = false
            }),
            builder.addCase(addTicketDetails.pending, (state: any) => {
                state.addPurchaseOrderLoading = true
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTicketDetails.fulfilled, (state: any) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = true
            }),
            builder.addCase(addTicketDetails.rejected, (state: any) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTransaction.pending, (state: any) => {
                state.addTransactionLoading = true
                state.addTransactionSuccess = false
            }),
            builder.addCase(addTransaction.fulfilled, (state: any) => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = true
            }),
            builder.addCase(addTransaction.rejected, (state: any) => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.pending, (state: any) => {
                state.verifyTransactionLoading = true
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.fulfilled, (state: any) => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = true
            }),
            builder.addCase(VerifyTransaction.rejected, (state: any) => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(addPaymentTerm.pending, (state: any) => {
                state.addPaymentTermLoading = true
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(addPaymentTerm.fulfilled, (state: any) => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = true
            }),
            builder.addCase(addPaymentTerm.rejected, (state: any) => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(editTicketDetails.pending, (state: any) => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editTicketDetails.fulfilled, (state: any) => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editTicketDetails.rejected, (state: any) => {
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editSkipStatus.pending, (state: any) => {
                state.editSkipStatusLoading = true
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editSkipStatus.fulfilled, (state: any) => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = true
            }),
            builder.addCase(editSkipStatus.rejected, (state: any) => {
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.pending, (state: any) => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.fulfilled, (state: any) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatus.rejected, (state: any) => {
                state.editTicketStatusSuccess = false
            })
    }
})

export const { setDisplayValues, setChargerCurrentRuleValue, setChargerDisplayValues, setCurrentRuleValue, setProjectsForm,
    setCityCurrentRuleValue, setCityDisplayValues, setStateCurrentRuleValue, setStateDisplayValues, setPages, setSelectedValues,
    setSelectedTab, setDealTab, resetRaiseTicketForm, resetProjectStates } = ProjectsSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default ProjectsSlice.reducer
