import { api } from "../../api"
import { HistoryResponse, IResponse, TabsResponseType, TicketDataResponseType } from "../Common/commonTypes"

const MODULE_URL = `tech_support`

const techSupportService = api.injectEndpoints({
    endpoints: build => ({
        getTechSupportTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/ticket_total`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    read_filter: params.data.ReadUnread ?? "all",
                    search_key: params.data.search_key ?? "",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            transformResponse: (response: TabsResponseType) => response.data.message,
            providesTags: ["TechSupport"],
        }),
        getTechSupportData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>({
            query: params => ({
                url: `${MODULE_URL}/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            providesTags: ["TechSupport"],
        }),
        getTSTicketDetails: build.query<IResponse<any>, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ticket_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "TechSupport", id: arg.ticket_id }],
        }),
        getTSTicketHistory: build.query<HistoryResponse, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/history`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "TechSupport", id: arg.ticket_id }],
        }),
    }),
})

export const {
    useLazyGetTechSupportTabsQuery,
    useLazyGetTechSupportDataQuery,
    useGetTSTicketDetailsQuery,
    useGetTSTicketHistoryQuery,
} = techSupportService

export { techSupportService }
