import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import success_icon from '../../assets/images/success_icon.svg'
import error_icon from '../../assets/images/error_icon.svg'
import styles from './Alert.module.scss';
import { setAlertLoading } from "./AlertSlice";

const AlertToaster = () => {
    const { alert_type, alert_msg, alert_loading } = useSelector((store: any) => store.Alert)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()

    const renderToastr = () => (
        <>{
            open ? alert_type === 'success' ? <div className={styles.success}>
                <span>
                    <img src={success_icon} alt='success_icon' className={styles.img_mssg} />
                </span>
                <span>{alert_msg}</span>
            </div> :
                <div className={styles.error}>
                    <span>
                        <img src={error_icon} alt='error_icon' className={styles.img_mssg} />
                    </span>
                    <span>{alert_msg}</span>
                </div>
                : null
        }
        </>
    )

    useEffect(() => {
        if (alert_loading === true) {
            setOpen(true)
            setTimeout(() => {
                dispatch(setAlertLoading(false))
            }, 2500)
        }
        else {
            setOpen(false)
        }
    }, [alert_loading])

    return (
        renderToastr()
    )

}

export default AlertToaster;