import React, { useContext, useEffect, useState } from 'react'
import useGetDeviceType from '../../hooks/useGetDeviceType';

const sidemenuContext:any = React.createContext(null);

function SidemenuContextprovider({children}:any) {
  const {deviceType} = useGetDeviceType()
  const [toggle, setToggle] = useState(false)

  useEffect(()=>{
    if(deviceType==="Desktop"){
      setToggle(true)
    }
  },[deviceType])

  const handleSideToggle = () => {
    setToggle(!toggle)
  };
    
  return (
    <sidemenuContext.Provider value={{handleSideToggle,toggle, setToggle}}>{children}</sidemenuContext.Provider>
  )
}

export const useSidemenuContext = () => {
    const {handleSideToggle,toggle, setToggle}:any = useContext(sidemenuContext);
    return {handleSideToggle,toggle, setToggle};
}

export default SidemenuContextprovider