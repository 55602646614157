import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../../../Auth/components/axiosInterceptors'
import axios from 'axios'
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice'
const initialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    addLoading: false,
    editLoading: false,
    EditSuccess: false
}

export const getMembers = createAsyncThunk(
    'users', async (thunkAPI:any) => {
        try {
            const params = {
                page : thunkAPI.page,
                per_page : thunkAPI.per_page,
                search_key : thunkAPI.search_key || "",
                team_id : thunkAPI.team_id || "",
                role_id : thunkAPI.role_id || "",
                status:thunkAPI.status || "all"
            }
            // const response = await axiosInstance.get(`users?page=${pageNo}&per_page=${per_page}&search_key=${search_key}&team_id=${team_id}&role_id=${role_id}`);
            const response = await axiosInstance.get('users',{params:params});
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });


export const editMembers: any = createAsyncThunk(
    'editMembers', async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response = await axiosInstance(`users`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(thunkAPI.data)
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getMembers({
                page : thunkAPI.get?.pageNumber,
                per_page : thunkAPI.get.per_page,
                search_key : thunkAPI?.get?.search_key || "",
                team_id : thunkAPI?.get?.team_id || "",
                role_id : thunkAPI?.get?.role_id || "",
                status:thunkAPI?.get?.status || ""}))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error?.response?.data?.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

export const deactivateMembers: any = createAsyncThunk(
    'deactivateMembers', async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response = await axiosInstance(`users/deactivate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({id:thunkAPI.id,status:thunkAPI.status})
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getMembers({
                page : thunkAPI?.filter?.page,
                per_page : thunkAPI?.filter?.per_page,
                search_key : thunkAPI?.filter?.search_key || "",
                team_id : thunkAPI?.filter?.team_id || "",
                role_id : thunkAPI?.filter?.role_id || "",
                status:thunkAPI?.filter?.status || ""}))
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error?.response?.data?.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    });

export const addMembers: any = createAsyncThunk('addMemberss',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const res = await axiosInstance('users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(thunkAPI.data)
            })
            dispatch(setAlertFunc({ type: 'success', msg: res?.data?.message }))
            res.status === 201 && dispatch(getMembers({
                page : thunkAPI.get?.pageNumber,
                per_page : thunkAPI.get.per_page,
                search_key : thunkAPI?.get?.search_key || "",
                team_id : thunkAPI?.get?.team_id || "",
                role_id : thunkAPI?.get?.role_id || "",
                status:thunkAPI?.get?.status || ""}))
            return res.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error?.response?.data?.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
)

const MemberSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getMembers.pending, (state: any) => {
            state.loading = true
            state.users = []
            state.totalPages = 1
            state.EditSuccess = false
            state.addSuccess = false
        }),
            builder.addCase(getMembers.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.users = payload
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getMembers.rejected, (state: any) => {
                state.loading = false
                state.users = []
                state.totalPages = 0
            }),
            builder.addCase(addMembers.pending, (state: any) => {
                state.addLoading = true
                state.addSuccess = false
            }),
            builder.addCase(addMembers.fulfilled, (state: any) => {
                state.addLoading = false
                state.addSuccess = true
            }),
            builder.addCase(addMembers.rejected, (state: any) => {
                state.addLoading = false
                state.addSuccess = false
            }),
            builder.addCase(editMembers.pending, (state: any) => {
                state.editLoading = true
                state.EditSuccess = false
            }),
            builder.addCase(editMembers.fulfilled, (state: any) => {
                state.editLoading = false
                state.EditSuccess = true
                state.addSuccess = false
            }),
            builder.addCase(editMembers.rejected, (state: any) => {
                state.editLoading = false
                state.EditSuccess = false
            })

    }
})

export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default MemberSlice.reducer