import React, { useEffect, useState } from 'react'

function useGetDeviceType() {

    const [deviceType, setDeviceType] = useState('');

    function getDeviceType() {
        const width = window.innerWidth;
        if (width <= 767) {
            return 'Mobile';
        } /* else if (width <= 1023) {
            return 'Tablet';
        } */ else {
            return 'Desktop';
        }
    }


    useEffect(() => {
        function handleResize() {
          const type = getDeviceType();
          setDeviceType(type);
        }
      
        handleResize(); // Call the function initially
      
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    
  return {deviceType}
}

export default useGetDeviceType