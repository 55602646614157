import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    module_id: 0,
    menu_loading: false,
    submodule_id:0,
}

export const SideMenuSlice = createSlice({
    name: 'SideMenuSlice',
    initialState,
    reducers: {
        setModuleLoading: (state: any, value: any) => {
            state.menu_loading = value.payload
        },
        setModuleFunc: (state: any, payload: any) => {
            state.menu_loading = true;
            state.module_id = payload.payload
        },
        setSubmoduleFunc: (state: any, payload: any) => {
            state.submodule_id = payload.payload
        }
    }
})
export const { setModuleLoading, setModuleFunc, setSubmoduleFunc } = SideMenuSlice.actions;

export default SideMenuSlice.reducer