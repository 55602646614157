import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    addAccountFormValue: {
        billing_address: '',
        billing_state: '',
        billing_city: '',
        billing_pincode:'',
        gstin: '',
        gst_file:'',
        account_name:'',
        account_id:'',
        parent_link:''
    }
}

    const AccountSlice = createSlice({
        name: 'accounts',
        initialState,
        reducers: {
            setAddAccountForm: (state: any, payload: any) => {
                state.addAccountFormValue = payload?.payload
            }
        }
    })
export const {setAddAccountForm} = AccountSlice.actions
    export default AccountSlice.reducer
