import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { axiosInstance } from '../../.../../../Auth/components/axiosInterceptors';
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';


const initialState = {
    name: "",
    team_id: 0,
    loading: false,
    addSuccess: false,
    positions: [],
    editPositionsLoading: false,
    editSuccess: false,
    addPostionsLoading: false
}

export const getPositions: any = createAsyncThunk(
    'positions', async (
        team_id,
        thunkApi) => {
    try {
        const params = {
            team_id : team_id
        }
        const response: any = await axiosInstance('positions', {
            method: 'GET',
            params: params
        })
        // const response: any = await axiosInstance(`positions?team_id=${team_id}`, {
        //     method: 'GET',
        //     data: JSON.stringify(thunkApi)
        // })
        return await response.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message });
    }
});

export const addPositions: any = createAsyncThunk(
    'addPosition',
    async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`positions`, {
                method: 'POST',
                data: JSON.stringify(thunkApi)
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getPositions(thunkApi.team_id));
            return response
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.response.data.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    }
);

export const editPositions: any = createAsyncThunk(
    'editPositions', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`positions`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi[0])
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getPositions(thunkApi[1].currentID));
            return response;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.response.data.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    })

const positionSlice = createSlice({
    name: 'positions',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getPositions.pending, (state: any) => {
            state.loading = true
            state.editSuccess = false
            state.addSuccess = false
        }),
            builder.addCase(getPositions.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.positions = payload
                state.editSuccess = false
                state.addSuccess = false
            }),
            builder.addCase(getPositions.rejected, (state: any) => {
                state.loading = false
            }),
            builder.addCase(addPositions.pending, (state: any) => {
                state.addPostionsLoading = true
                state.addSuccess = false
            }),
            builder.addCase(addPositions.fulfilled, (state: any) => {
                state.addPostionsLoading = false
                state.addSuccess = true
            }),
            builder.addCase(addPositions.rejected, (state: any) => {
                state.addPostionsLoading = false
            }),
            builder.addCase(editPositions.pending, (state: any) => {
                state.editPositionsLoading = true
                state.editSuccess = false
            }),
            builder.addCase(editPositions.fulfilled, (state: any) => {
                state.editPositionsLoading = false
                state.editSuccess = true
            }),
            builder.addCase(editPositions.rejected, (state: any) => {
                state.editPositionsLoading = false
                state.editSuccess = false
            })
    },
})

export const getAllPositions = (state: any) => state.positions
export const getCurrentTeamId = (state: any) => state.positions.team_id
export const addPositionSuccess = (state: any) => state.positions.addSuccess
export const addPostionsLoading = (state: any) => state.positions.addPostionsLoading
export const editPositionSuccess = (state: any) => state.positions.addSuccess

export default positionSlice.reducer