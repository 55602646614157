import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    users: [],
    loading: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    SurveyFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: {
            id: "",
        },
        TicketStartDate: "",
        TicketEndDate: "",
        ETDStartDate: "",
        ETDEndDate: "",
        ClosureStartDate: "",
        ClosureEndDate: "",
        TaxationStartDate: "",
        TaxationEndDate: "",
        search_key: "",
        sow: "",
        ReadUnread: "all",
        ticketType: "all",
        vendor_ids: [],
        parent_status: "all_tab",
        no_of_spots: "",
    },
    SurveyDealTab: 0,
    selectedTab: 0,
    vendorDisplayValues: [],
    vendorCurrentRules: [],
}

const SurveySlice = createSlice({
    name: "Survey",
    initialState,
    reducers: {
        setSurveyForm: (state, action) => {
            state.SurveyFormValue = action?.payload
        },
        setDisplayValues: (state, action) => {
            state.displayValues = action?.payload
        },
        setCurrentRuleValue: (state, action) => {
            state.currentRules = action?.payload
        },
        setVendorDisplayValues: (state, action) => {
            state.vendorDisplayValues = action?.payload
        },
        setVendorCurrentRuleValue: (state, action) => {
            state.vendorCurrentRules = action?.payload
        },
        setChargerDisplayValues: (state, action) => {
            state.chargerDisplayValues = action?.payload
        },
        setChargerCurrentRuleValue: (state, action) => {
            state.chargerCurrentRules = action?.payload
        },
        setCityDisplayValues: (state, action) => {
            state.CityDisplayValues = action?.payload
        },
        setCityCurrentRuleValue: (state, action) => {
            state.CityCurrentRules = action?.payload
        },
        setStateDisplayValues: (state, action) => {
            state.StateDisplayValues = action?.payload
        },
        setStateCurrentRuleValue: (state, action) => {
            state.StateCurrentRules = action?.payload
        },
        setPages: (state, action) => {
            state.page = action?.payload
        },
        setSelectedValues: (state, action) => {
            state.selectedValue = action?.payload
        },
        setDealTab: (state, action) => {
            state.SurveyDealTab = action?.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action?.payload
        },
    }
})

export const {
    setVendorCurrentRuleValue,
    setVendorDisplayValues,
    setDisplayValues,
    setChargerCurrentRuleValue,
    setChargerDisplayValues,
    setCurrentRuleValue,
    setSurveyForm,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setPages,
    setSelectedValues,
    setSelectedTab,
    setDealTab,
} = SurveySlice.actions

export const getAllUser = (state: any) => state.users.loading

export default SurveySlice.reducer