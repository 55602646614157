import { axiosInstance } from "../Auth/components/axiosInterceptors";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import urls from "../../enums/urlList";
import { Logger } from "sass";
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice";




const initialState = {
    loading: false,
    data: [],
    procurementTotalPages: 1,
    success: false,
    // comments: [],
    dealDetailsLoading: false,
    dealDetailsData: [],
    dealDetailsButtonsLoading: false,
    dealDetailsButtonStatusData: [],
    assignedToLoading: false,
    assignedToData: [],
    editDispatchLoading: false,
    editDispatchedSuccess: false,
    deleteCommentsLoading: false,
    procurementHistoryLoading: false,
    procurementHistoryData: [],
    ProcurementFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        UpdateStartDate: '',
        UpdateEndDate: '',
        search_key: '',
        ReadUnread: 'all'
    },
    ProcStateDisplayValues: [],
    ProcStateCurrentRules: [],
    ProcCityDisplayValues: [],
    ProcCityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    page: 1,
    selectedValue: 10,
    addCommentSuccess: false,
    editCommentSuccess: false,
    deleteCommentSuccess: false
}

export const getProcurement: any = createAsyncThunk(
    'procurement', async (
        obj: any,
        thunkApi) => {
    try {
        const response: any = await axiosInstance(`${urls.procurement}/ticket`, {
            method: 'GET',
            params: {
                page: obj.page ?? '1',
                per_page: obj.selectedValue ?? '10',
                'state_id': JSON.stringify(obj.data.stateId ?? []),
                'status_id': JSON.stringify(obj.data.statusId ?? []),
                "city_id": JSON.stringify(obj.data.cityId ?? []),
                'assigned_to': obj.data.assigned_to ?? "",
                'age': JSON.stringify(obj.data.age ?? []),
                'age_op': obj.data.age_op ?? "",
                'raised_on_end': obj.data.TicketEndDate ?? "",
                'raised_on_start': obj.data.TicketStartDate ?? "",
                'updated_on_start': obj.data.UpdateStartDate ?? "",
                'updated_on_end': obj.data.UpdateEndDate ?? "",
                'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                'search_key': obj.data.search_key ?? "",
                'read_filter': obj.data.ReadUnread ?? 'all'
            }
        })
        return response.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message });
    }
});

// export const getComments: any = createAsyncThunk(
//     'comments', async (thunkApi: any, { getState, dispatch }) => {
//         try {
//             const response: any = await axiosInstance(`proc_mgmt/comment`, {
//                 method: 'GET',
//                 params: { ticket_id: thunkApi }
//                 // data: JSON.stringify(thunkApi)
//             })
//             // response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: "Success" }))
//             return response.data;
//         } catch (error: any) {
//             dispatch(setAlertFunc({ type: 'error', msg: error.message }))
//             return thunkApi.rejectWithValue({ error: error.message });
//         }
//     });

// export const addComments: any = createAsyncThunk(
//     'addComments', async (thunkApi: any, { getState, dispatch }) => {
//         const response: any = await axiosInstance(`${urls.base_url}proc_mgmt/comment`, {
//             method: 'POST',
//             data: thunkApi.data
//         }).then(
//             (data: any) => {

//                 dispatch(getComments(thunkApi.data.get("ticket_id")));
//                 data.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: data.data.message }))
//                 return data;
//             }
//         ).catch((error: any) => {
//             dispatch(setAlertFunc({ type: 'error', msg: error.message }))
//             return thunkApi.rejectWithValue({ error: error.message });
//         })
//         return response
//     });

export const editDispatchInitiated: any = createAsyncThunk(
    'editDispatchInitiated', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`proc_mgmt/ticket`, {
                method: 'PUT',
                data: thunkApi.data
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getDealDetails(thunkApi.ticket_id))
            dispatch(getDealDetailsStatusButton(thunkApi.ticket_id))
            return response
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

// export const editComments: any = createAsyncThunk(
//     'editComments', async (thunkApi: any, { getState, dispatch }) => {
//         const response: any = await axiosInstance(`proc_mgmt/comment`, {
//             method: 'PUT',
//             data: JSON.stringify(thunkApi.data)
//         }).then(
//             (data: any) => {
//                 dispatch(getComments(thunkApi.ticket_id))
//                 data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
//                 return data
//             }
//         ).catch((error: any) => {
//             dispatch(setAlertFunc({ type: 'error', msg: error.message }))
//             return thunkApi.rejectWithValue({ error: error.message });
//         })
//         return response
//     });

// export const deleteComments: any = createAsyncThunk(
//     'deleteComments', async (thunkApi: any, { getState, dispatch }) => {
//         const response: any = await axiosInstance(`proc_mgmt/comment?id=${thunkApi.commentId}&user_id=${thunkApi.userId}`, {
//             method: 'DELETE',
//             // data: JSON.stringify(thunkApi)
//         }).then(
//             (data: any) => {
//                 dispatch(getComments(thunkApi.ticket_id))
//                 data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
//                 return data
//             }
//         ).catch((error: any) => {
//             dispatch(setAlertFunc({ type: 'error', msg: error.message }))
//             return thunkApi.rejectWithValue({ error: error.message });
//         })
//         return response
//     });

// deal details



export const getDealDetails: any = createAsyncThunk(
    'dealDetails', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`proc_mgmt/proc_ticket_details?`, {
                method: 'GET',
                params: { ticket_id: thunkApi }
                // data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getDealDetailsStatusButton: any = createAsyncThunk(
    'dealDetailsButtons', async (thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`proc_mgmt/proc_status_buttons`, {
                method: 'GET',
                params: { ticket_id: thunkApi }
                // data: JSON.stringify(thunkApi)
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

// export const getAssignedTo: any = createAsyncThunk(
//     'get/assigned-to', async (thunkApi: any, { getState, dispatch }) => {
//         try {
//             const response: any = await axiosInstance(`proc_mgmt/proc_assign_to`, {
//                 method: 'GET',
//                 params: { search_key: thunkApi.search_key ?? '', ticket_id: thunkApi.ticket_id ?? '' }
//             })
//             return response.data;
//         } catch (error: any) {
//             return thunkApi.rejectWithValue({ error: error.message });
//         }
//     });


// export const editAssignedTo: any = createAsyncThunk(
//     'editAssignedTo', async (thunkApi: any, { getState, dispatch }) => {
//         try {
//             const response: any = await axiosInstance(`proc_mgmt/proc_assign_to`, {
//                 method: 'PUT',
//                 data: JSON.stringify(thunkApi)
//             })
//             dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
//             const currentState: any = getState()
//             response.status === 200 && dispatch(getProcurement({ data: currentState.procurement.ProcurementFormValue, page: thunkApi.page, selectedValue: thunkApi.per_page }))
//             return response.data;
//         }
//         catch (error: any) {
//             dispatch(setAlertFunc({ type: 'error', msg: error.message }))
//             return thunkApi.rejectWithValue({ error: error.message });
//         }
//     });

export const getProcurementHistory: any = createAsyncThunk(
    'procurementHistory', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`proc_mgmt/proc_ticket_history`, {
                method: 'GET',
                params: { ticket_id: ticket_id }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editProcTicketDetails: any = createAsyncThunk(
    'editProcTicketDetails', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/edit_deal_details`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getDealDetails(thunkApi?.ticket_id));
            response.status === 200 && dispatch(getDealDetailsStatusButton(thunkApi?.ticket_id))

            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
const ProcurementSlice = createSlice({
    name: 'procurement',
    initialState,
    reducers: {
        setProcurementForm: (state: any, payload: any) => {
            state.ProcurementFormValue = payload?.payload
        },
        setProcDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setProcCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setProcChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setProcChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setProcStateDisplayValues: (state: any, payload: any) => {
            state.ProcStateDisplayValues = payload?.payload
        },
        setProcStateCurrentRuleValue: (state: any, payload: any) => {
            state.ProcStateCurrentRules = payload?.payload
        },
        setProcCityDisplayValues: (state: any, payload: any) => {
            state.ProcCityDisplayValues = payload?.payload
        },
        setProcCityCurrentRuleValue: (state: any, payload: any) => {
            state.ProcCityCurrentRules = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        }
    },
    extraReducers: (builder: any) => {
        return builder.addCase(getProcurement.pending, (state: any) => {
            state.loading = true;
            state.totalPages = 1
        }),
            builder.addCase(getProcurement.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.data = payload
                state.procurementTotalPages = payload.totalPages
                state.deleteCommentSuccess = false
                state.addCommentSuccess = false
                state.editCommentSuccess = false
            }),
            builder.addCase(getProcurement.rejected, (state: any) => {
                state.loading = false;
                state.totalPages = 1
                state.data = []
                state.procurementTotalPages = 0
            }),
            // builder.addCase(getComments.pending, (state: any) => {
            //     state.commentsLoading = true
            // }),
            // builder.addCase(getComments.fulfilled, (state: any, { payload }: any) => {
            //     state.commentsLoading = false
            //     state.comments = payload
            // }),
            // builder.addCase(getComments.rejected, (state: any) => {
            //     state.commentsLoading = false
            // }),


            /*************************************** Deal Details********************************* */


            builder.addCase(getDealDetails.pending, (state: any) => {
                state.dealDetailsLoading = true
                state.dealDetailsData = [];
                state.editDispatchedSuccess = false
            }),
            builder.addCase(getDealDetails.fulfilled, (state: any, { payload }: any) => {
                state.dealDetailsLoading = false;
                state.editDispatchedSuccess = false
                state.dealDetailsData = payload;
            }),
            builder.addCase(getDealDetails.rejected, (state: any) => {
                state.dealDetailsLoading = false
                state.dealDetailsData = [];
            }),
            builder.addCase(getDealDetailsStatusButton.pending, (state: any) => {
                state.dealDetailsButtonsLoading = true
            }),
            builder.addCase(getDealDetailsStatusButton.fulfilled, (state: any, { payload }: any) => {
                state.dealDetailsButtonsLoading = false;
                state.dealDetailsButtonStatusData = payload;
            }),
            builder.addCase(getDealDetailsStatusButton.rejected, (state: any) => {
                state.dealDetailsButtonsLoading = false
            }),
            // builder.addCase(getAssignedTo.pending, (state: any) => {
            //     state.assignedToLoading = true
            // }),
            // builder.addCase(getAssignedTo.fulfilled, (state: any, { payload }: any) => {
            //     state.assignedToLoading = false
            //     state.assignedToData = payload
            // }),
            // builder.addCase(getAssignedTo.rejected, (state: any) => {
            //     state.assignedToLoading = false
            // }),
            builder.addCase(editDispatchInitiated.pending, (state: any) => {
                state.editDispatchLoading = true
                state.editDispatchedSuccess = false
            }),
            builder.addCase(editDispatchInitiated.fulfilled, (state: any, { payload }: any) => {
                state.editDispatchLoading = false
                state.editDispatchedSuccess = true
            }),
            builder.addCase(editDispatchInitiated.rejected, (state: any) => {
                state.editDispatchLoading = false
                state.editDispatchedSuccess = false
            }),
            // builder.addCase(editComments.pending, (state: any) => {
            //     state.editCommentLoading = true
            //     state.editCommentSuccess = false
            // }),
            // builder.addCase(editComments.fulfilled, (state: any, { payload }: any) => {
            //     state.editCommentLoading = false
            //     state.editCommentSuccess = true
            // }),
            // builder.addCase(editComments.rejected, (state: any) => {
            //     state.editCommentLoading = false
            //     state.editCommentSuccess = false
            // }),
            // builder.addCase(addComments.pending, (state: any) => {
            //     state.addCommentLoading = true
            //     state.addCommentSuccess = false
            // }),
            // builder.addCase(addComments.fulfilled, (state: any, { payload }: any) => {
            //     state.addCommentLoading = false
            //     state.addCommentSuccess = true
            // }),
            // builder.addCase(addComments.rejected, (state: any) => {
            //     state.addCommentLoading = false
            //     state.addCommentSuccess = false
            // }),
            // builder.addCase(deleteComments.pending, (state: any) => {
            //     state.deleteCommentsLoading = true
            //     state.deleteCommentSuccess = false
            // }),
            // builder.addCase(deleteComments.fulfilled, (state: any, { payload }: any) => {
            //     state.deleteCommentsLoading = false
            //     state.deleteCommentSuccess = true
            // }),
            // builder.addCase(deleteComments.rejected, (state: any) => {
            //     state.deleteCommentsLoading = false
            //     state.deleteCommentSuccess = false
            // }),
            builder.addCase(getProcurementHistory.pending, (state: any) => {
                state.procurementHistoryLoading = true;
            }),
            builder.addCase(getProcurementHistory.fulfilled, (state: any, { payload }: any) => {
                state.procurementHistoryLoading = false
                state.procurementHistoryData = payload
            }),
            builder.addCase(getProcurementHistory.rejected, (state: any) => {
                state.procurementHistoryLoading = false;
                state.procurementHistoryData = []
            })
    }
})

export const { setProcurementForm, setProcDisplayValues, setProcCurrentRuleValue, setProcChargerCurrentRuleValue,
    setProcChargerDisplayValues, setSelectedValues, setPages, setProcCityCurrentRuleValue, setProcCityDisplayValues,
    setProcStateCurrentRuleValue, setProcStateDisplayValues } = ProcurementSlice.actions

export const procurementData = (state: any) => state.procurement.data;
export const procurementLoading = (state: any) => state.procurement.loading;
export const getAllComments = (state: any) => state.procurement.comments;
export const getDealDetailsLoading = (state: any) => state.procurement.dealDetailsLoading;
export const dealDetailsData = (state: any) => state.procurement.dealDetailsData;
export const dealDetailsStatusButton = (state: any) => state.procurement.dealDetailsButtonStatusData;
export const deleteCommentsLoading = (state: any) => state.procurement.deleteCommentsLoading;

export default ProcurementSlice.reducer