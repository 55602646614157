import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    CustomerSupportFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: {
            id: "",
        },
        TicketStartDate: "",
        TicketEndDate: "",
        ETDStartDate: "",
        ETDEndDate: "",
        ClosureStartDate: "",
        ClosureEndDate: "",
        TaxationStartDate: "",
        TaxationEndDate: "",
        search_key: "",
        sow: "",
        parent_status: "all_tab",
    },
    CustomerSupportDealTab: 0,
    selectedTab: 0,
}

const CustomerSupportSlice = createSlice({
    name: "CustomerSupport",
    initialState,
    reducers: {
        setCustomerSupportForm: (state: any, payload: any) => {
            state.CustomerSupportFormValue = payload?.payload
        },
        setDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state: any, payload: any) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state: any, payload: any) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state: any, payload: any) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state: any, payload: any) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state: any, payload: any) => {
            state.CustomerSupportDealTab = payload?.payload
        },
        setSelectedTab: (state: any, payload: any) => {
            state.selectedTab = payload?.payload
        },
    },
})

export const {
    setDisplayValues,
    setChargerCurrentRuleValue,
    setChargerDisplayValues,
    setCurrentRuleValue,
    setCustomerSupportForm,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setPages,
    setSelectedValues,
    setSelectedTab,
    setDealTab,
} = CustomerSupportSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default CustomerSupportSlice.reducer
