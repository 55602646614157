import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../../../Auth/components/axiosInterceptors'
import urls from "../../../../enums/urlList";

import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice'
const initialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    DemolitionFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: '',
        age: [],
        more_than_age: '',
        between_age1: '',
        between_age2: '',
        less_than_age: '',
        assigned_to: '',
        TicketStartDate: '',
        TicketEndDate: '',
        ETDStartDate: '',
        ETDEndDate: '',
        ClosureStartDate: '',
        ClosureEndDate: '',
        TaxationStartDate: '',
        TaxationEndDate: '',
        search_key: '',
        sow: '',
        parent_status: 'demolition_open'
    },
    DemolitionTabsLoading: false,
    DemolitionTabsData: [],
    DemolitionLoading: false,
    DemolitionData: [],
    DemolitionDetailLoading: false,
    DemolitionDealDetailData: [],
    DemolitionHistoryLoading: false,
    DemolitionHistoryData: [],
    DemolitionOthersLoading: false,
    DemolitionOthersData: [],
    DemolitionButtonLoading: false,
    DemolitionButtonData: [],
    deleteTransactionLoading: false,
    deleteTransactionSuccess: false,
    deletePaymentSuccess: false,
    deletePaymentLoading: false,
    addPurchaseOrderSuccess: false,
    addPurchaseOrderLoading: false,
    editTicketDetailsSuccess: false,
    editTicketDetailsLoading: false,
    editDemolitionFormSuccess: false,
    editDemolitionFormLoading: false,
    editChargerDetailsLoading:false,
    editChargerDetailsSuccess:false,
    editTicketStatusSuccess: false,
    editTicketStatusLoading: false,
    skipStatusLoading: false,
    skipStatusData: [],
    editSkipStatusSuccess: false,
    editSkipStatusLoading: false,
    ChargerTemplateLoading: false,
    addTransactionSuccess: false,
    addTransactionLoading: false,
    verifyTransactionSuccess: false,
    verifyTransactionLoading: false,
    addPaymentTermSuccess: false,
    addPaymentTermLoading: false,
    ChargerTemplateData: [],
    PaymentConditionLoading: false,
    PaymentConditionData: [],
    PaymentTimeLoading: false,
    PaymentTimeData: [],
    DemolitionDealTab: 0,
    selectedTab: 0
}

export const getDemolitionTabs: any = createAsyncThunk(
    'getDemolitionTabs', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/ticket_total`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_id': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'due_start': obj.data.ClosureStartDate ?? '',
                    'due_end': obj.data.ClosureEndDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getDemolitionData: any = createAsyncThunk(
    'getDemolitionData', async (obj: any, { thunkApi, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/${obj.data.parent_status ?? 'demolition_open'}`, {
                method: 'GET',
                params: {
                    'state_id': JSON.stringify(obj.data.stateId ?? []),
                    'status_id': JSON.stringify(obj.data.statusId ?? []),
                    "city_id": JSON.stringify(obj.data.cityId ?? []),
                    'assigned_to': obj.data.assigned_to?.id ?? '',
                    'age': JSON.stringify(obj.data.age ?? []),
                    'age_op': obj.data.age_op ?? '',
                    'raised_on_end': obj.data.TicketEndDate ?? '',
                    'raised_on_start': obj.data.TicketStartDate ?? '',
                    'due_start': obj.data.ClosureStartDate ?? '',
                    'due_end': obj.data.ClosureEndDate ?? '',
                    'charger_type': JSON.stringify(obj.data.chargerIds ?? []),
                    'search_key': obj.data.search_key ?? '',
                    'read_filter': obj.data.ReadUnread ?? 'all',
                    'per_page': obj?.selectedValue,
                    'page': obj?.page
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getTicketDetails: any = createAsyncThunk(
    'ticketDetail', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/ticket_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getTicketHistory: any = createAsyncThunk(
    'history', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/ticket_history`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getOtherDetails: any = createAsyncThunk(
    'getOtherDetails', async (ticket_id: any, thunkApi: any) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/other_details`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getSkipStatuses: any = createAsyncThunk(
    'getStatuses', async (obj: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/statuses`, {
                method: 'GET',
                params: {
                    "search_key": obj.search_key ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getButtonDropdown: any = createAsyncThunk(
    'getButtonDropdown', async (ticket_id: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/button_dropdown`, {
                method: 'GET',
                params: {
                    "ticket_id": ticket_id ?? '',
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const getPaymentConditions: any = createAsyncThunk(
    'getPaymentConditions', async (ticket_id, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/conditions`, {
                method: 'GET',
                params:{
                    ticket_id: ticket_id
                }
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const getPaymentTime: any = createAsyncThunk(
    'getPaymentTime', async (__, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/demolitions/time_uom`, {
                method: 'GET',
            })
            return response.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const addTicketDetails: any = createAsyncThunk(
    'addFormat',
    async (thunkAPI: any, { getState, dispatch }) => {

        try {
            const response: any = await axiosInstance(`${urls.base_url}demolitions/ticket_details`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkAPI.data.get('ticket_id')))
            if (response.status === 200 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const editDemolitionForm: any = createAsyncThunk(
    'editDemolition', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/demolitions/ticket_details`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
    export const editChargerDetails: any = createAsyncThunk(
        'editChargerDetails', async (thunkApi: any, { getState, dispatch }: any) => {
            try {
                const response: any = await axiosInstance(`${urls.base_url}operations/demolitions/charger_details`, {
                    method: 'PUT',
                    data: thunkApi.data,
                });
                response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
                return response.data;
            }
            catch (error: any) {
                dispatch(setAlertFunc({ type: 'error', msg: error.message }))
                return thunkApi.rejectWithValue({ error: error.message });
            }
        });
export const addPaymentTerm: any = createAsyncThunk(
    'addPaymentTerm',
    async (thunkAPI: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}demolitions/payment_term`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getTicketDetails(thunkAPI.data.get('ticket_id')))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addTransaction: any = createAsyncThunk(
    'addTransaction',
    async (thunkAPI: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}demolitions/transaction`, {
                method: 'POST',
                data: thunkAPI.data,
            })
            response.status === 201 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 201 && dispatch(getTicketDetails(thunkAPI.data.get("ticket_id")))
            response.status === 201 && dispatch(getButtonDropdown(thunkAPI.data.get("ticket_id")))
            if (response.status === 201 && thunkAPI.toggle !== undefined) {
                thunkAPI.toggle();
            }
            return response.data
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const VerifyTransaction: any = createAsyncThunk(
    'VerifyTransaction', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}demolitions/verify_transaction`, {
                method: 'POST',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const editTicketDetails: any = createAsyncThunk(
    'editTicketDetails', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/demolitions/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            // response.status === 200 && dispatch(getOtherDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editSkipStatus: any = createAsyncThunk(
    'editSkipStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}demolitions/skip_to_status`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });

export const editTicketStatus: any = createAsyncThunk(
    'editTicketStatus', async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}demolitions/ticket`, {
                method: 'PUT',
                data: thunkApi.data,
            });
            response.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            response.status === 200 && dispatch(getTicketDetails(thunkApi.data.get("ticket_id")))
            response.status === 200 && dispatch(getButtonDropdown(thunkApi.data.get("ticket_id")))
            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle();
            }
            return response.data;
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    });
export const deleteTransaction: any = createAsyncThunk(
    'deleteTransaction', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`operations/demolitions/transaction?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&transaction_id=${thunkApi?.transaction_id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
export const deletePaymentTerm: any = createAsyncThunk(
    'deletePaymentTerm', async (thunkApi: any, { getState, dispatch }) => {
        const response: any = await axiosInstance(`operations/demolitions/payment_term?ticket_id=${thunkApi?.ticket_id}&user_id=${thunkApi?.user_id}&payment_term_id=${thunkApi?.payment_term_id}`, {
            method: 'DELETE',
        }).then(
            (data: any) => {
                dispatch(getTicketDetails(thunkApi?.ticket_id))
                data.status === 200 && dispatch(setAlertFunc({ type: 'success', msg: data?.data?.message }))
                return data
            }
        ).catch((error: any) => {
            dispatch(setAlertFunc({ type: 'error', msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        })
        return response
    });
const DemolitionSlice = createSlice({
    name: 'Demolition',
    initialState,
    reducers: {
        setDemolitionForm: (state: any, payload: any) => {
            state.DemolitionFormValue = payload?.payload
        },
        setDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state: any, payload: any) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state: any, payload: any) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state: any, payload: any) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state: any, payload: any) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state: any, payload: any) => {
            state.DemolitionDealTab = payload?.payload
        },
        setSelectedTab: (state: any, payload: any) => {
            state.selectedTab = payload?.payload
        },

    },
    extraReducers: (builder: any) => {
        return builder.addCase(getDemolitionTabs.pending, (state: any) => {
            state.DemolitionTabsLoading = true
        }),
            builder.addCase(getDemolitionTabs.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionTabsLoading = false
                state.DemolitionTabsData = payload.data.message
            }),
            builder.addCase(getDemolitionTabs.rejected, (state: any) => {
                state.DemolitionTabsLoading = false
                state.DemolitionTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getDemolitionData.pending, (state: any) => {
                state.DemolitionLoading = true
                state.totalPages = 1
            }),
            builder.addCase(getDemolitionData.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionLoading = false
                state.DemolitionData = payload
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getDemolitionData.rejected, (state: any) => {
                state.DemolitionLoading = false
                state.DemolitionData = []
                state.totalPages = 0
            }),
            builder.addCase(getTicketDetails.pending, (state: any) => {
                state.DemolitionDetailLoading = true
                // state.editTicketStatusSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(getTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionDetailLoading = false
                state.editTicketStatusSuccess = false
                state.editTicketDetailsSuccess = false
                state.addPurchaseOrderSuccess = false
                state.addTransactionSuccess = false
                state.addPaymentTermSuccess = false
                state.verifyTransactionSuccess = false
                state.DemolitionDealDetailData = payload
            }),
            builder.addCase(getTicketDetails.rejected, (state: any) => {
                state.DemolitionDetailLoading = false
                state.DemolitionDealDetailData = []
            }),
            builder.addCase(getTicketHistory.pending, (state: any) => {
                state.DemolitionHistoryLoading = true
            }),
            builder.addCase(getTicketHistory.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionHistoryLoading = false
                state.DemolitionHistoryData = payload
            }),
            builder.addCase(getTicketHistory.rejected, (state: any) => {
                state.DemolitionHistoryLoading = false
                state.DemolitionHistoryData = []
            }),
            builder.addCase(getOtherDetails.pending, (state: any) => {
                state.DemolitionOthersLoading = true
            }),
            builder.addCase(getOtherDetails.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionOthersLoading = false
                state.DemolitionOthersData = payload
            }),
            builder.addCase(getOtherDetails.rejected, (state: any) => {
                state.DemolitionOthersLoading = false
                state.DemolitionOthersData = []
            }),
            builder.addCase(getSkipStatuses.pending, (state: any) => {
                state.skipStatusLoading = true
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state: any, { payload }: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, (state: any) => {
                state.skipStatusLoading = false
                state.skipStatusData = []
            }),
            builder.addCase(getPaymentTime.pending, (state: any) => {
                state.PaymentTimeLoading = true
            }),
            builder.addCase(getPaymentTime.fulfilled, (state: any, { payload }: any) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = payload
            }),
            builder.addCase(getPaymentTime.rejected, (state: any) => {
                state.PaymentTimeLoading = false
                state.PaymentTimeData = []
            }),
            builder.addCase(getPaymentConditions.pending, (state: any) => {
                state.PaymentConditionLoading = true
            }),
            builder.addCase(getPaymentConditions.fulfilled, (state: any, { payload }: any) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = payload
            }),
            builder.addCase(getPaymentConditions.rejected, (state: any) => {
                state.PaymentConditionLoading = false
                state.PaymentConditionData = []
            }),
            builder.addCase(getButtonDropdown.pending, (state: any) => {
                state.DemolitionButtonLoading = true
            }),
            builder.addCase(getButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionButtonLoading = false
                state.DemolitionButtonData = payload?.data
            }),
            builder.addCase(getButtonDropdown.rejected, (state: any) => {
                state.DemolitionButtonLoading = false
                state.DemolitionButtonData = []
            }),
            builder.addCase(deleteTransaction.pending, (state: any) => {
                state.deleteTransactionLoading = true
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deleteTransaction.fulfilled, (state: any, { payload }: any) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = true
            }),
            builder.addCase(deleteTransaction.rejected, (state: any) => {
                state.deleteTransactionLoading = false
                state.deleteTransactionSuccess = false
            }),
            builder.addCase(deletePaymentTerm.pending, (state: any) => {
                state.deletePaymentLoading = true
                state.deletePaymentSuccess = false
            }),
            builder.addCase(deletePaymentTerm.fulfilled, (state: any, { payload }: any) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = true
            }),
            builder.addCase(deletePaymentTerm.rejected, (state: any) => {
                state.deletePaymentLoading = false
                state.deletePaymentSuccess = false
            }),
            builder.addCase(addTicketDetails.pending, (state: any) => {
                state.addPurchaseOrderLoading = true
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTicketDetails.fulfilled, (state: any) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = true
            }),
            builder.addCase(addTicketDetails.rejected, (state: any) => {
                state.addPurchaseOrderLoading = false
                state.addPurchaseOrderSuccess = false
            }),
            builder.addCase(addTransaction.pending, (state: any) => {
                state.addTransactionLoading = true
                state.addTransactionSuccess = false
            }),
            builder.addCase(addTransaction.fulfilled, (state: any) => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = true
            }),
            builder.addCase(addTransaction.rejected, (state: any) => {
                state.addTransactionLoading = false
                state.addTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.pending, (state: any) => {
                state.verifyTransactionLoading = true
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(VerifyTransaction.fulfilled, (state: any) => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = true
            }),
            builder.addCase(VerifyTransaction.rejected, (state: any) => {
                state.verifyTransactionLoading = false
                state.verifyTransactionSuccess = false
            }),
            builder.addCase(addPaymentTerm.pending, (state: any) => {
                state.addPaymentTermLoading = true
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(addPaymentTerm.fulfilled, (state: any) => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = true
            }),
            builder.addCase(addPaymentTerm.rejected, (state: any) => {
                state.addPaymentTermLoading = false
                state.addPaymentTermSuccess = false
            }),
            builder.addCase(editTicketDetails.pending, (state: any) => {
                state.editTicketDetailsLoading = true
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editTicketDetails.fulfilled, (state: any) => {
                state.editTicketDetailsLoading = false
                state.editTicketDetailsSuccess = true
            }),
            builder.addCase(editTicketDetails.rejected, (state: any) => {
                state.editTicketDetailsSuccess = false
            }),
            builder.addCase(editDemolitionForm.pending, (state: any) => {
                state.editDemolitionFormLoading = true
                state.editDemolitionFormSuccess = false
            }),
            builder.addCase(editDemolitionForm.fulfilled, (state: any) => {
                state.editDemolitionFormLoading = false
                state.editDemolitionFormSuccess = true
            }),
            builder.addCase(editDemolitionForm.rejected, (state: any) => {
                state.editDemolitionFormSuccess = false
            }),
            builder.addCase(editChargerDetails.pending, (state: any) => {
                state.editChargerDetailsLoading = true
                state.editChargerDetailsSuccess = false
            }),
            builder.addCase(editChargerDetails.fulfilled, (state: any) => {
                state.editChargerDetailsLoading = false
                state.editChargerDetailsSuccess = true
            }),
            builder.addCase(editChargerDetails.rejected, (state: any) => {
                state.editChargerDetailsSuccess = false
            }),
            builder.addCase(editSkipStatus.pending, (state: any) => {
                state.editSkipStatusLoading = true
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editSkipStatus.fulfilled, (state: any) => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = true
            }),
            builder.addCase(editSkipStatus.rejected, (state: any) => {
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.pending, (state: any) => {
                state.editTicketStatusLoading = true
                state.editTicketStatusSuccess = false
            }),
            builder.addCase(editTicketStatus.fulfilled, (state: any) => {
                state.editTicketStatusLoading = false
                state.editTicketStatusSuccess = true
            }),
            builder.addCase(editTicketStatus.rejected, (state: any) => {
                state.editTicketStatusSuccess = false
            })
    }
})

export const { setDisplayValues, setChargerCurrentRuleValue, setChargerDisplayValues, setCurrentRuleValue, setDemolitionForm,
    setCityCurrentRuleValue, setCityDisplayValues, setStateCurrentRuleValue, setStateDisplayValues, setPages, setSelectedValues,
    setSelectedTab, setDealTab } = DemolitionSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default DemolitionSlice.reducer