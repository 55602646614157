import { createSlice } from "@reduxjs/toolkit"

import { IResponse } from "../Common/commonTypes"

type LogisticsFormValue = {
    cityId: any
    stateId: any
    destinationcityId: any
    destinationstateId: any
    chargerIds: any
    statusId: any
    age_op: string
    age: any
    more_than_age: string
    between_age1: string
    between_age2: string
    less_than_age: string
    assigned_to: Record<string, any>
    TicketStartDate: string
    TicketEndDate: string
    ETDStartDate: string
    ETDEndDate: string
    search_key: string
    sow: string
    ticketType: string
    delivery_vendor: Record<string, any>
    parent_status: string
}

type InitialState = {
    users: any[]
    loading: boolean
    addSuccess: boolean
    totalPages: number
    page: number
    selectedValue: number
    addLoading: boolean
    editLoading: boolean
    EditSuccess: boolean
    chargerDisplayValues: any[]
    chargerCurrentRules: any[]
    StateDisplayValues: any[]
    StateCurrentRules: any[]
    CityDisplayValues: any[]
    CityCurrentRules: any[]
    DestinationStateDisplayValues: any[]
    DestinationStateCurrentRules: any[]
    DestinationCityDisplayValues: any[]
    DestinationCityCurrentRules: any[]
    currentRules: any[]
    displayValues: any[]
    LogisticsFormValue: LogisticsFormValue
    addLogisticsTicketForm: {
        source_site_id: Record<string, any>
        source_latitude: string
        source_longitude: string
        source_address: string
        source_city: Record<string, any>
        source_state: Record<string, any>
        source_pincode: string
        source_poc_name: string
        source_poc_contact: string
        source_poc_email: string
        delivery_request: string
        time: string
        project_name: string
        delivery_date: string
        destination_site_id: Record<string, any>
        destination_latitude: string
        destination_longitude: string
        destination_address: string
        destination_city: Record<string, any>
        destination_state: Record<string, any>
        destination_pincode: string
        destination_poc_name: string
        destination_poc_contact: string
        destination_poc_email: string
    }
    LogisticsDealTab: number
    selectedTab: number
    logisticsDealDetailData?: IResponse<any>
}

const initialState: InitialState = {
    users: [],
    loading: false,
    addSuccess: false,
    totalPages: 1,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    DestinationStateDisplayValues: [],
    DestinationStateCurrentRules: [],
    DestinationCityDisplayValues: [],
    DestinationCityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    LogisticsFormValue: {
        cityId: [],
        stateId: [],
        destinationcityId: [],
        destinationstateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: {},
        TicketStartDate: "",
        TicketEndDate: "",
        ETDStartDate: "",
        ETDEndDate: "",
        search_key: "",
        ticketType: "all",
        sow: "",
        delivery_vendor: {},
        parent_status: "all_tab",
    },
    addLogisticsTicketForm: {
        source_site_id: {},
        source_latitude: "",
        source_longitude: "",
        source_address: "",
        source_city: {},
        source_state: {},
        source_pincode: "",
        source_poc_name: "",
        source_poc_contact: "",
        source_poc_email: "",
        delivery_request: "",
        time: "",
        project_name: "",
        delivery_date: "",
        destination_site_id: {},
        destination_latitude: "",
        destination_longitude: "",
        destination_address: "",
        destination_city: {},
        destination_state: {},
        destination_pincode: "",
        destination_poc_name: "",
        destination_poc_contact: "",
        destination_poc_email: "",
    },
    LogisticsDealTab: 0,
    selectedTab: 0,
    logisticsDealDetailData: undefined,
}

const LogisticsSlice = createSlice({
    name: "logistics",
    initialState,
    reducers: {
        setProcurementForm: (state, action) => {
            state.LogisticsFormValue = action?.payload
        },
        setDisplayValues: (state, action) => {
            state.displayValues = action?.payload
        },
        setCurrentRuleValue: (state, action) => {
            state.currentRules = action?.payload
        },
        setChargerDisplayValues: (state, action) => {
            state.chargerDisplayValues = action?.payload
        },
        setChargerCurrentRuleValue: (state, action) => {
            state.chargerCurrentRules = action?.payload
        },
        setCityDisplayValues: (state, action) => {
            state.CityDisplayValues = action?.payload
        },
        setCityCurrentRuleValue: (state, action) => {
            state.CityCurrentRules = action?.payload
        },
        setStateDisplayValues: (state, action) => {
            state.StateDisplayValues = action?.payload
        },
        setStateCurrentRuleValue: (state, action) => {
            state.StateCurrentRules = action?.payload
        },
        setDestinationCityDisplayValues: (state, action) => {
            state.DestinationCityDisplayValues = action?.payload
        },
        setDestinationCityCurrentRuleValue: (state, action) => {
            state.DestinationCityCurrentRules = action?.payload
        },
        setDestinationStateDisplayValues: (state, action) => {
            state.DestinationStateDisplayValues = action?.payload
        },
        setDestinationStateCurrentRuleValue: (state, action) => {
            state.DestinationStateCurrentRules = action?.payload
        },
        setPages: (state, action) => {
            state.page = action?.payload
        },
        setSelectedValues: (state, action) => {
            state.selectedValue = action?.payload
        },
        setDealTab: (state, action) => {
            state.LogisticsDealTab = action?.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action?.payload
        },
        setAddLogisticsTicketForm: (state, action) => {
            state.addLogisticsTicketForm = action?.payload
        },
        setLogisticsDealDetailsData: (state, action) => {
            state.logisticsDealDetailData = action.payload
        },
    }
})

export const {
    setDisplayValues,
    setChargerCurrentRuleValue,
    setChargerDisplayValues,
    setCurrentRuleValue,
    setProcurementForm,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setPages,
    setSelectedValues,
    setSelectedTab,
    setDealTab,
    setAddLogisticsTicketForm,
    setDestinationCityCurrentRuleValue,
    setDestinationCityDisplayValues,
    setDestinationStateCurrentRuleValue,
    setDestinationStateDisplayValues,
    setLogisticsDealDetailsData
} = LogisticsSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default LogisticsSlice.reducer
