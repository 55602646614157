import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {axiosInstance} from '../../../Auth/components/axiosInterceptors'
import axios from 'axios'
const initialState = {
    manager: [],
    managerLoading: false,

}

export const getManager = createAsyncThunk(
    'teams/manager', async (thunkAPI:any) => {
        try {
            const params = {
                team_id : thunkAPI.team_id,
                position_id : thunkAPI.position_id,
                role_id : thunkAPI.role_id,
            }
            // const response = await axiosInstance.get(`users/managers?team_id=${team_id}`);
            const response = await axiosInstance.get('users/managers',{params:params});
            return await response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    })

const ManagerSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(getManager.pending, (state: any) => {
            state.managerLoading = true

        }),
            builder.addCase(getManager.fulfilled, (state: any, { payload }: any) => {
                state.managerLoading = false
                state.manager = payload
            }),
            builder.addCase(getManager.rejected, (state: any) => {
                state.managerLoading = false
                state.manager = []
            })
    }
})

export default ManagerSlice.reducer