import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from '../../.../../../Auth/components/axiosInterceptors';
import { setAlertFunc } from '../../../../components/AlertToaster/AlertSlice';

interface TeamState {
    name: string,
    pageNo: string | number,
    per_page: string | number,
    loading: boolean,
    addSuccess: boolean,
    teams: any,
    TeamTotalPages: string | number
}

const initialState: TeamState = {
    name: "",
    pageNo: "",
    per_page: "",
    loading: false,
    addSuccess: false,
    teams: [],
    TeamTotalPages: 1
}


export const getTeams = createAsyncThunk(
    'teams', async (
        [pageNo, per_page]: any,
        thunkApi) => {
    try {
        const params = {
            page : pageNo,
            per_page : per_page
        }
        const response: any = await axiosInstance(`teams`, {
            method: 'GET',
            params: params
        })
        return response.data;
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message });
    }
});

export const addTeam = createAsyncThunk(
    'addTeams',
    async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`teams`, {
                method: 'POST',
                data: JSON.stringify(thunkApi.teamName)
            })
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            dispatch(getTeams([thunkApi.pages, thunkApi.per_page]))
            return response
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.response.data.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    }
);

export const editTeam = createAsyncThunk(
    'editTeams', async (thunkApi: any, { getState, dispatch }) => {
        try {
            const response: any = await axiosInstance(`teams`, {
                method: 'PUT',
                data: JSON.stringify(thunkApi.data.teamName)
            })
            
            dispatch(setAlertFunc({ type: 'success', msg: response?.data?.message }))
            
            dispatch(getTeams([thunkApi.pages.page, thunkApi.pages.selectedValue]))
            return response
        }
        catch (error: any) {
            dispatch(setAlertFunc({ type: 'error', msg: error.response.data.message }))
            return thunkApi.rejectWithValue({ error: error.message });
        }
    })

const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        return builder.addCase(getTeams.pending, (state) => {
            state.loading = true
            state.teams = []
            state.TeamTotalPages = 1
            state.addSuccess = false
        }),
            builder.addCase(getTeams.fulfilled, (state, { payload }: any) => {
                state.loading = false
                state.teams = payload
                state.TeamTotalPages = payload.totalPages
                state.addSuccess = false
            }),
            builder.addCase(getTeams.rejected, (state) => {
                state.loading = false
                state.teams = []
                state.TeamTotalPages = 0
            }),
            builder.addCase(addTeam.pending, (state) => {
                state.loading = true
                state.addSuccess = false
            }),
            builder.addCase(addTeam.fulfilled, (state) => {
                state.loading = false
                state.addSuccess = true
            }),
            builder.addCase(addTeam.rejected, (state) => {
                state.loading = false
            })
    },
})

export const getAllTeams = (state: TeamState) => state.teams
export const addTeamSuccess = (state: TeamState) => state.teams.addSuccess
export const editTeamSuccess = (state: TeamState) => state.teams.addSuccess

export default teamSlice.reducer