import { api } from "../../api"
import {
    HistoryResponse,
    IResponse,
    StatusButton,
    TabsResponseType,
    TicketDataResponseType,
} from "../Common/commonTypes"

type CustomerSupportTicketDetailsResponseType = IResponse<any>

const MODULE_URL = `customer_support`

const customerSupportService = api.injectEndpoints({
    endpoints: build => ({
        //for fetching tab and no of tickets in respective tabs
        getCustomerSupportTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/ticket_total`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    due_start: params.data.ClosureStartDate ?? "",
                    due_end: params.data.ClosureEndDate ?? "",
                    etd_start: params.data.TaxationStartDate ?? "",
                    etd_end: params.data.TaxationEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            transformResponse: (response: TabsResponseType) => response.data.message,
            providesTags: ["CustomerSupport"],
        }),
        // for fetching table data
        getCustomerSupportData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>(
            {
                query: params => ({
                    url: `${MODULE_URL}/${params.data.parent_status ?? "all_tab"}`,
                    params: {
                        state_id: JSON.stringify(params.data.stateId ?? []),
                        status_id: JSON.stringify(params.data.statusId ?? []),
                        city_id: JSON.stringify(params.data.cityId ?? []),
                        assigned_to: params.data.assigned_to?.id ?? "",
                        age: JSON.stringify(params.data.age ?? []),
                        age_op: params.data.age_op ?? "",
                        raised_on_end: params.data.TicketEndDate ?? "",
                        raised_on_start: params.data.TicketStartDate ?? "",
                        due_start: params.data.ClosureStartDate ?? "",
                        due_end: params.data.ClosureEndDate ?? "",
                        etd_start: params.data.TaxationStartDate ?? "",
                        etd_end: params.data.TaxationEndDate ?? "",
                        charger_type: JSON.stringify(params.data.chargerIds ?? []),
                        search_key: params.data.search_key ?? "",
                        read_filter: params.data.ReadUnread ?? "all",
                        per_page: params?.selectedValue,
                        page: params?.page,
                    },
                }),
                providesTags: ["CustomerSupport"],
            }
        ),
        // for fetching ticket data
        getCSTicketDetails: build.query<CustomerSupportTicketDetailsResponseType, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ticket_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "CustomerSupport", id: arg.ticket_id }],
        }),
        // for fetching history
        getCSTicketHistory: build.query<HistoryResponse, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/history`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "CustomerSupport", id: arg.ticket_id }],
        }),
        // for getting button dropdown opt`ions
        getCSButtonDropdown: build.query({
            query: (params: { ticket_id: string }) => ({
                url: `${MODULE_URL}/button_dropdown`,
                params: params,
            }),
            transformResponse: (response: IResponse<StatusButton[]>) => response.data,
            providesTags: (result, error, arg) => [{ type: "CustomerSupport", id: arg.ticket_id }],
        }),
        // for changing ticket status
        editCSTicketDetails: build.mutation<unknown, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket`,
                method: "PUT",
                body: body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "CustomerSupport", id: arg.get("ticket_id") as string },
                "CustomerSupport",
            ],
        }),
    }),
})

export const {
    useLazyGetCustomerSupportTabsQuery,
    useLazyGetCustomerSupportDataQuery,
    useGetCSTicketDetailsQuery,
    useGetCSTicketHistoryQuery,
    useGetCSButtonDropdownQuery,
    useEditCSTicketDetailsMutation,
} = customerSupportService

export { customerSupportService }
