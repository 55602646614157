import React, { useEffect, useState } from 'react';
import search from "../../assets/images/search_iconV2.png";
import styles from './SearchInput.module.scss';

type SearchInputProps = {
  placeholder: any,
  onSearch: any,
  searchIcon?: boolean,
  value?: string | number,
  type?: string,
  maxCharacter?: any,
  height?: any,
  step?: any,
  fontsize?: any,
  additionalValidation?: string,
  error?: boolean,
  showError?: boolean,
  disabled?: boolean,
  width?: any,
  onPaste?: any,
  errorText?: any,
  errorStyle?: React.CSSProperties
}

function SearchInputV2(props: SearchInputProps) {
  const { placeholder, onSearch, searchIcon, value: valueFromParent, type, onPaste, maxCharacter = null, height, step, fontsize, additionalValidation = null, error, showError, disabled, width } = props;
  const [value, setValue] = useState<number | string>("");

  useEffect(() => {
    setValue(valueFromParent ?? "")
  }, [valueFromParent])

  function isValidLat(num: any) {
    let number = Number(num)
    const isInRange = number >= -90 && number <= 90;
    const decimalPartLength = number.toString().split('.')[1]?.length || 0;
    const hasValidDecimal = decimalPartLength <= 10;
    return isInRange && hasValidDecimal;
  }

  function isError() {
    const hasError = error && showError;
    const isValueEmpty = showError && (!value && value !== 0);
    return hasError || isValueEmpty;
  }

  function isValidLong(num: any) {
    let number = Number(num)
    const isInRange = number >= -180 && number <= 180;
    const decimalPartLength = number.toString().split('.')[1]?.length || 0;
    const hasValidDecimal = decimalPartLength <= 10;
    return isInRange && hasValidDecimal;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "-" && additionalValidation === "0") {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      onSearch(value);
    }
  }

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    if (input === document.activeElement) {
      input.blur();
    }
  };
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (additionalValidation === '2' || additionalValidation === '3') {
      let copiedText = e.clipboardData.getData('text')
      if (copiedText?.includes(',')) {
        e.preventDefault();
        onPaste(copiedText.split(','))
      }
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (maxCharacter) {
      if (inputValue.length <= maxCharacter) {
        if (additionalValidation) {
          if (additionalValidation === "0") {
            if (Number(value) >= 0) {
              setValue(inputValue)
              if (!searchIcon) onSearch(inputValue);
            }
          }
          if (additionalValidation === "1") {
            if (Number(inputValue) >= 0 && Number(inputValue) <= 100) {
              setValue(inputValue)
              if (!searchIcon) onSearch(inputValue);
            }
          }
          if (additionalValidation === "2") {
            let lat: string | number = inputValue;
            if (lat.toString().split(".")[1]?.length > 10) {
              lat = Math.floor(parseFloat(lat) * 10000000000) / 10000000000;
            }
            if (isValidLat(lat)) {
              setValue(lat);
              if (!searchIcon) onSearch(lat);
            }
          }
          if (additionalValidation === "3") {
            let long: string | number = inputValue;
            if (long.toString().split(".")[1]?.length > 10) {
              long = Math.floor(parseFloat(long) * 10000000000) / 10000000000;
            }
            if (isValidLong(long)) {
              setValue(long);
              if (!searchIcon) onSearch(long);
            }
          }
          if (additionalValidation === "4") {
            if (Number(inputValue) >= 0 && Number(inputValue) <= 10) {
              setValue(inputValue)
              if (!searchIcon) onSearch(inputValue);
            }
          }
          if (additionalValidation === "5") {
            if (Number(value) >= 0) {
              setValue(Math.floor(Number(inputValue)).toString())
              if (!searchIcon) onSearch(Math.floor(Number(inputValue)).toString());
            }
          }
          if (additionalValidation === "6") {
            const re = /^[0-9\b]+$/;
            if ((inputValue === '' || re.test(inputValue))) {
              if (inputValue?.length <= 10) {
                setValue(inputValue)
              }
              if (!searchIcon) onSearch(inputValue);
            }
          }
        }
        else {
          setValue(inputValue)
          if (!searchIcon) onSearch(inputValue);
        }
      }
    } else {
      if (additionalValidation) {
        if (additionalValidation === "0") {
          if (Number(value) >= 0) {
            setValue(inputValue)
            if (!searchIcon) onSearch(inputValue);
          }
        }
        if (additionalValidation === "1") {
          if (Number(inputValue) >= 0 && Number(inputValue) <= 100) {
            setValue(inputValue)
            if (!searchIcon) onSearch(inputValue);
          }
        }
        if (additionalValidation === "2") {
          let lat: string | number = inputValue;
          if (lat.toString().split('.')[1]?.length > 10) {
            lat = Math.floor(parseFloat(lat) * 10000000000) / 10000000000;
          }
          if (isValidLat(lat)) {
            setValue(lat)
            if (!searchIcon) onSearch(lat);
          }
        }
        if (additionalValidation === "3") {
          let long: string | number = inputValue;
          if (long.toString().split('.')[1]?.length > 10) {
            long = Math.floor(parseFloat(long) * 10000000000) / 10000000000;
          }
          if (isValidLong(long)) {
            setValue(long)
            if (!searchIcon) onSearch(long);
          }
        }
        if (additionalValidation === "4") {
          if (Number(inputValue) >= 0 && Number(inputValue) <= 10) {
            setValue(Math.floor(Number(inputValue)))
            if (!searchIcon) onSearch(inputValue);
          }
        }
        if (additionalValidation === "5") {
          if (Number(value) >= 0) {
            setValue(Math.floor(Number(inputValue)).toString())
            if (!searchIcon) onSearch(Math.floor(Number(inputValue)).toString());
          }
        }
      }
      else {
        setValue(inputValue)
        if (!searchIcon) onSearch(inputValue);
      }

    }
  };

  return (
    <div>
      <div className={`${styles.input_container} ${disabled ? styles.disabled : ""} ${isError() ? styles.error_box : ""}`}>
        <input
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          onWheel={handleWheel}
          onChange={handleChange}
          onPaste={handlePaste}
          value={value}
          onKeyDown={handleKeyDown}
          type={type}
          min={additionalValidation === "0" ? 0 : undefined}
          style={{ fontSize: fontsize ? fontsize : "", height: height ? height : "", cursor: disabled ? "not-allowed" : "pointer", width: width ? width : "" }}
        />
        {searchIcon ?
          <span className={styles.search_button} onClick={() => { onSearch(value) }}>
            <img src={search} alt="search" />
          </span> : ""
        }
      </div>
      {isError() && (props?.errorText !== undefined) && <div className={styles?.error_text} style={props?.errorStyle}>{props?.errorText}</div>}
    </div>
  )
}

export default SearchInputV2;
