import React from "react"
import styles from "./Button.module.scss"

interface ButtonSmallProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    outline ?: boolean
}

const ButtonSmall = (props: ButtonSmallProps) => {
    return (
        <button {...props} className={`${props.outline ? styles.button_small_outline : styles.button_small} ${props.className}`}>
            {props.children}
        </button>
    )
}

export default ButtonSmall
