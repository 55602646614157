import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosInstance } from './axiosInterceptors'

const initialState = {
    // login: [],
    loginLoading: false,
    loginSuccess: false
}

// export const getOTP = createAsyncThunk(
//     'login/send-otp', async (phone: any, thunkAPI) => {
//         try {
//             const response = await axiosInstance.get(`login/send-otp?phone=${phone}`);
//             return await response.data;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue({ error: error.message });
//         }
//     })

export const sendOTP: any = createAsyncThunk(
    'login/send-otp',
    async (thunkAPI: any) => {
        try {
            const response: any = await axiosInstance(`login/send-otp`, {
                method: 'POST',
                data: thunkAPI
            })
            return response.data;
        }
        catch (error: any) {
            thunkAPI.InavalidNumber(true)
            thunkAPI.InavalidText(error.message )
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        return builder.addCase(sendOTP.pending, (state: any) => {
            state.loginLoading = true
            state.loginSuccess = false

        }),
            builder.addCase(sendOTP.fulfilled, (state: any) => {
                state.loginLoading = false
                state.loginSuccess = true
            }),
            builder.addCase(sendOTP.rejected, (state: any) => {
                state.loginLoading = false
                state.loginSuccess = false
            })
    }
})

export default loginSlice.reducer