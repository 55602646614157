import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosInstance } from "../../../Auth/components/axiosInterceptors"

interface Position {
    id: number
    name: string
    manager_reqd: number
}

interface GetPositionArgs {
    id: string | number
}

const initialState = {
    positionLoading: false,
    positions: [] as Position[],
}

export const getPosition = createAsyncThunk<Position[], GetPositionArgs, { rejectValue: { error: string } }>(
    "teams/position",
    async ({ id }, thunkAPI) => {
        try {
            const params = {
                team_id: id,
            }
            const response = await axiosInstance.get("positions", { params: params })
            return await response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.message })
        }
    }
)

const PositionSlice = createSlice({
    name: "positions",
    initialState,
    reducers: {},
    extraReducers: builder => {
        return (
            builder.addCase(getPosition.pending, state => {
                state.positionLoading = true
            }),
            builder.addCase(getPosition.fulfilled, (state, { payload }: { payload: Position[] }) => {
                state.positionLoading = false
                state.positions = payload
            }),
            builder.addCase(getPosition.rejected, state => {
                state.positionLoading = false
                state.positions = []
            })
        )
    },
})
export const getAllPositions = (state: typeof initialState) => state.positions

export default PositionSlice.reducer
