import { createSlice } from "@reduxjs/toolkit"

interface FinanceFormValue {
    cityId: any
    stateId: any
    chargerIds: any
    statusId: any
    age_op: string
    age: any
    more_than_age: string
    between_age1: string
    between_age2: string
    less_than_age: string
    assigned_to: Record<string, any>
    TicketStartDate: string
    TicketEndDate: string
    ETDStartDate: string
    ETDEndDate: string
    ClosureStartDate: string
    ClosureEndDate: string
    TaxationStartDate: string
    TaxationEndDate: string
    search_key: string
    sow: string
    parent_status: string
}

interface InitialState {
    page: number
    selectedValue: number
    chargerDisplayValues: any
    chargerCurrentRules: any
    StateDisplayValues: any
    StateCurrentRules: any
    CityDisplayValues: any
    CityCurrentRules: any
    currentRules: any
    displayValues: any
    FinanceFormValue: FinanceFormValue,
    FinanceDealTab:number,
    selectedTab:number
}

const initialState: InitialState = {
    page: 1,
    selectedValue: 10,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    FinanceFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: {},
        TicketStartDate: "",
        TicketEndDate: "",
        ETDStartDate: "",
        ETDEndDate: "",
        ClosureStartDate: "",
        ClosureEndDate: "",
        TaxationStartDate: "",
        TaxationEndDate: "",
        search_key: "",
        sow: "",
        parent_status: "all_tab",
    },
    FinanceDealTab: 0,
    selectedTab: 0,
}

const FinanceSlice = createSlice({
    name: "Finance",
    initialState,
    reducers: {
        setFinanceForm: (state, payload) => {
            state.FinanceFormValue = payload?.payload
        },
        setDisplayValues: (state, payload) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state, payload) => {
            state.currentRules = payload?.payload
        },
        setChargerDisplayValues: (state, payload) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state, payload) => {
            state.chargerCurrentRules = payload?.payload
        },
        setCityDisplayValues: (state, payload) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state, payload) => {
            state.CityCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state, payload) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state, payload) => {
            state.StateCurrentRules = payload?.payload
        },
        setPages: (state, payload) => {
            state.page = payload?.payload
        },
        setSelectedValues: (state, payload) => {
            state.selectedValue = payload?.payload
        },
        setDealTab: (state, payload) => {
            state.FinanceDealTab = payload?.payload
        },
        setSelectedTab: (state, payload) => {
            state.selectedTab = payload?.payload
        },
    },
})

export const {
    setDisplayValues,
    setChargerCurrentRuleValue,
    setChargerDisplayValues,
    setCurrentRuleValue,
    setFinanceForm,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setPages,
    setSelectedValues,
    setSelectedTab,
    setDealTab,
} = FinanceSlice.actions
export const getAllUser = (state: any) => state.users.loading
export const addUserSuccess = (state: any) => state.users.addSuccess

export default FinanceSlice.reducer
