import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosInstance } from "../Auth/components/axiosInterceptors"

const initialState = {
    loading: false,
    addSuccess: false,
    notifications: [],
    editLoading: false,
    EditSuccess: false,
    notification_token: "",
    notificationPermission: "",
    notificationUnreadTab: "All",
    hasUnreadData: false,
    theposition: "",
    page: 1,
    isShown: false
}

export const getNotifications: any = createAsyncThunk("getNotifications", async (thunkApi: any, { getState }) => {
    try {
        const currentState: any = getState()
        const response: any = await axiosInstance(
            thunkApi?.tab === "Unread" || currentState?.notification.notificationUnreadTab === "Unread"
                ? `notifications/notifications?unseen_flag=true`
                : `notifications/notifications`,
            {
                method: "GET",
                params: {
                    page: thunkApi?.page ?? 1,
                    per_page: 20,
                },
            }
        )
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const editNotifications: any = createAsyncThunk("editNotifications", async (thunkApi: any, { dispatch }) => {
    try {
        const response: any = await axiosInstance(`notifications/notifications`, {
            method: "PUT",
            data: JSON.stringify(thunkApi),
        })
        response.status === 200 && dispatch(getNotifications())
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const verifyNotifications: any = createAsyncThunk("verifyNotifications", async (thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`notifications/save_fcm_token`, {
            method: "POST",
            data: JSON.stringify(thunkApi),
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

const NotificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotificationPermission: (state: any, payload: any) => {
            state.notificationPermission = payload?.payload
        },
        setNotificationToken: (state: any, payload: any) => {
            state.notification_token = payload?.payload
        },
        setNotificationUnreadTab: (state: any, payload: any) => {
            state.notificationUnreadTab = payload?.payload
        },
        setUnreadData: (state: any, payload: any) => {
            state.hasUnreadData = payload?.payload
        },
        setThePosition: (state: any, payload: any) => {
            state.theposition = payload?.payload
        },
        setPage: (state, { payload }) => {
            state.page = payload
        },
        setIsShown: (state, { payload }) => {
            state.isShown = payload
        }
    },
    extraReducers: (builder: any) => {
        return (
            builder.addCase(getNotifications.pending, (state: any) => {
                state.loading = true
                state.notifications = []
                state.EditSuccess = false
            }),
            builder.addCase(getNotifications.fulfilled, (state: any, { payload }: any) => {
                state.loading = false
                state.notifications = payload
                state.EditSuccess = false
            }),
            builder.addCase(getNotifications.rejected, (state: any) => {
                state.loading = false
                state.notifications = []
            }),
            builder.addCase(editNotifications.pending, (state: any) => {
                state.EditSuccess = false
                state.editLoading = true
            }),
            builder.addCase(editNotifications.fulfilled, (state: any, { payload }: any) => {
                state.EditSuccess = true
                state.editLoading = false
            }),
            builder.addCase(editNotifications.rejected, (state: any) => {
                state.EditSuccess = false
                state.editLoading = false
            })
        )
    },
})

export const {
    setNotificationPermission,
    setNotificationToken,
    setNotificationUnreadTab,
    setUnreadData,
    setThePosition,
    setPage,
    setIsShown
} = NotificationSlice.actions
export const getAllNotifications = (state: any) => state.notifications
export const getNotificationToken = (state: any) => state.notification_token

export default NotificationSlice.reducer
