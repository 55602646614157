import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alert_msg: '',
    alert_type: '',
    alert_loading: false
}

export const AlertSlice = createSlice({
    name: 'AlertSlice',
    initialState,
    reducers: {
        setAlertLoading: (state: any, value: any) => {
            state.alert_loading = value.payload
        },
        setAlertFunc: (state: any, payload: any) => {
            state.alert_loading = true
            state.alert_msg = payload.payload.msg
            state.alert_type = payload.payload.type
        }
    }
})

export const { setAlertLoading, setAlertFunc } = AlertSlice.actions

export default AlertSlice.reducer