import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessArr:[]
}

export const RolesAccessSlice = createSlice({
    name: 'rolesAccess',
    initialState,
    reducers: {
        setRoleAccess: (state: any, payload: any) => {
            state.accessArr=payload.payload[0]
        }
    }
})
export const getAllAccesses = (state: any) => state.access.accessArr;

export const { setRoleAccess } = RolesAccessSlice.actions

export default RolesAccessSlice.reducer