import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    module_id: []
}

export const RolesSetModuleSlice = createSlice({
    name: 'RolesSetModuleSlice',
    initialState,
    reducers: {
        setModuleId: (state: any, value: any) => {
            const index = state.module_id.indexOf(value.payload);
            if (index === -1) {
                state.module_id.push(value.payload)
            }
            else {
                state.module_id.splice(index, 1)
            }
        }
    }
})

export const { setModuleId } = RolesSetModuleSlice.actions

export default RolesSetModuleSlice.reducer